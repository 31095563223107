.plans {
	&.container {
		max-width: 100% !important;
		margin-top: 10px;
	}
	.controls {
		display: flex;
		flex-direction: row;
		justify-content: space-between;

		.radio-button {
			&.vertical .form-check-label:before {
				width: 10px;
				height: 10px;
			}
			label {
				width: 58px;
			}

			&:not(:last-of-type) {
				width: 100%;
				&:after {
					content: '';
					border-top: 1px solid;
					width: calc(100% - 58px);
					height: 100%;
					display: inline-flex;
					align-items: center;
					position: relative;
					top: -5px;
				}
			}
		}
		hr {
			background: #8e8e93;
			width: 100%;
		}
	}

	.plan-container {
		padding: 0 10px;
	}

	@media screen and (max-width: 576px) {
		&.container {
			padding: 0;
		}
	}
}

.plan {
	padding: 15px 10px 0;
	box-shadow: rgba(0, 0, 0, 0.5) 0 2px 4px 0;
	align-items: center;
	margin: 15px auto;
	overflow: hidden;
	max-width: 437px;

	.ribbon {
		font-family: UntitledSans;
		background-color: #000;
		position: absolute;
		left: -50px;
		top: 30px;
		transform: rotate(-45deg);
		padding: 7px 50px;
		font-size: 12px;
		font-weight: 500;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: 0.5px;
		text-align: center;
		color: #fff;
	}

	.plan-link {
		text-decoration: underline;
		font-size: 18px;
		color: black;
		font-weight: bold;
	}

	.plan-link-placeholder {
		height: 27px;
	}

	&.card {
		border: none;
		border-top: 5px solid #000;

		&.selected {
			border-top: 5px solid #F88379;
		}

		&.selected-lux {
			border-top: 5px solid #DDB263;
		}

		.card-header {
			font-family: SabonLTPro-Italic;
			background: none;
			border: none;
			display: flex;
			align-items: baseline;
			flex-direction: row;
			font-size: 30px;
			padding: 0;
			width: 100%;
			justify-content: center;

			img {
				opacity: 0.57;
				width: 86px;
				padding-right: 15px;
			}
		}

		.card-body {
			font-family: UntitledSans-Regular;
			padding: 0 15px;
			display: flex;
			flex-direction: column;
			align-items: center;
			text-align: center;

			.price {
				font-family: UntitledSans-Medium;
				font-size: 30px;
				position: relative;
				&:before {
					content: '$';
					font-size: 19px;
					line-height: 50px;
					padding-right: 5px;
					position: absolute;
					top: 0;
					left: -15px;
				}
			}

			.description {
				font-size: 16px;
				line-height: 26px;
				letter-spacing: 0.16px;
				white-space: pre-line;
				margin: 0;
				padding-bottom: 10px;
			}

			.accordion {
				width: 100%;
				order: 3;

				.plan-item {
					position: relative;
					width: 100%;
					border-bottom: 1px solid #dbdbdb;
					padding: 17px 0;
					text-align: left;
					cursor: pointer;

					&:last-of-type {
						border-bottom: none;
					}

					&.open {
						&:after {
							transform: rotate(135deg);
						}
					}

					&:after {
						position: absolute;
						content: '';
						display: inline-block;
						width: 9px;
						height: 9px;
						border-right: 2px solid black;
						border-top: 2px solid black;
						transform: rotate(45deg);
						transition: transform .5s;
						right: 0;
						top: 25px;
					}

					.item-title {
						width: calc(100% - 10px);
						color: #000;
						padding: 0;
						text-align: left;
						font-size: 15px;
					}

					p {
						font-size: 15px;
						position: relative;
						top: 10px;
					}
				}
			}

			ul {
				order: 3;
				padding: 0 60px;
				li {
					font-size: 16px;
					width: 100%;
					padding: 0;
					text-align: left;
					line-height: 35px;

					strong {
						font-family: UntitledSans-Medium;
					}
				}
			}

			button {
				font-family: UntitledSans-Medium;
				order: 2;
				margin: 22px auto;
				padding: 15px 40px;
				font-size: 16px;
			}
		}
	}

	@media screen and (max-width: 576px) {
		&.plan{
			padding: 15px 10px 0;
			box-shadow: rgba(0, 0, 0, 0.5) 0 2px 4px 0;
			align-items: center;
			margin: 15px 5px;
			overflow: hidden;
			max-width: 437px;
		}
		&.card {
			border-right: 1px solid rgba(0,0,0,.125);
			border-left: 1px solid rgba(0,0,0,.125);
			border-bottom: 1px solid rgba(0,0,0,.125);

			.card-body {
				button {
					order: 3;
					margin-top: 0;
				}
				ul {
					order: 2;
					padding: 0 20px;
				}
			}
		}
	}
}