.gift-cards-page {
	margin: 0 auto;
	line-height: 1;
	max-width: none;

	.section {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 80px 0;

		@media screen and (max-width: 576px) {
			padding: 40px 0;
		}

		&:first-of-type {
			padding: 0;
		}

		.section-content {
			margin: 0 auto;
		}
	}

	.section-title {
		display: block;
		font-family: Didot, serif;
		font-size: 30px;
		line-height: 140%;
		text-align: center;
	}

	.btn {
		padding: 15px 68px;
		font-weight: normal;

		@media screen and (max-width: 576px) {
			padding: 18px 68px;
		}

		&.btn-light:hover {
			background: black;
			color: white;
		}
	}

	.section.hero {
		background-color: #FFFBF5;

		.section-content {
			display: flex;
			width: 100%;
			max-width: 1440px;

			@media screen and (max-width: 576px) {
				flex-direction: column-reverse;
			}
		}

		.text {
			display: flex;
			flex-grow: 1;
			align-items: center;
			justify-content: center;
			padding: 0 40px;

			@media screen and (max-width: 576px) {
				padding: 40px 16px;
				text-align: center;
			}

			.center {
				max-width: 425px;

				.page-title {
					font-family: Didot, serif;
					font-size: 50px;
					letter-spacing: -1.5px;
					white-space: nowrap;

					@media screen and (max-width: 576px) {
						font-size: 35px;
						letter-spacing: -1.05px;
					}
				}

				.page-subtitle {
					font-family: UntitledSans, sans-serif;
					font-size: 18px;
					font-weight: normal;
					line-height: 30px;
					margin-top: 24px;
				}

				.btn {
					margin-top: 24px;

					@media screen and (max-width: 576px) {
						margin-top: 32px;
					}
				}
			}
		}


		.image {
			max-width: 792px;
			max-height: 596px;

			@media screen and (max-width: 576px) {
				height: 470px;
			}


			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}

	.section.pricing-table {
		background: linear-gradient(180deg, #FFF 0%, #FDFAF6 100%);

		@media screen and (max-width: 576px) {
			padding: 40px 16px;
		}

		.section-title {
			@media screen and (max-width: 576px) {
				width: 298px;
				font-size: 28px;
			}
		}

		.gifts {
			all: unset;
			display: flex;
			justify-content: center;
			margin-top: 24px;

			@media screen and (max-width: 576px) {
				flex-direction: column;
				gap: 24px;
			}

			.gift {
				all: unset; // thanks, bootstrap
				display: flex;
				flex-direction: column;
				gap: 24px;
				width: 270px;
				padding: 40px;

				@media screen and (max-width: 576px) {
					gap: 20px;
					width: 298px;
					padding: 24px 0;
				}

				.image {
					width: 298px;
					height: 175px;
					margin: 0 -15px;
					overflow: hidden;
					border-radius: 10px;
					box-shadow: 68px 109px 36px rgba(112, 100, 81, 0.01), 43px 70px 33px rgba(112, 100, 81, 0.05), 24px 39px 28px rgba(112, 100, 81, 0.17), 11px 17px 20px rgba(112, 100, 81, 0.29), 3px 4px 11px rgba(112, 100, 81, 0.33);

					@media screen and (max-width: 576px) {
						margin: 0;
					}

					img {
						width: 300px;
					}
				}


					.title {
						all: unset;
						font-family: Didot, serif;
						font-size: 30px;
						margin-top: 16px;
					}

					.features {
						display: flex;
						flex-direction: column;
						gap: 18px;
						padding: 0;
						font-size: 16px;

						li {
							background: url("data:image/svg+xml,%3Csvg width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.95832 15.3168L3.81665 11.1752L4.41165 10.581L7.95832 14.1277L15.5883 6.49768L16.1825 7.09268L7.95832 15.3168Z' fill='%23161616'/%3E%3C/svg%3E%0A") left -4px no-repeat;
							padding-left: 32px;
						}
					}

				.description {
					font-size: 16px;
					line-height: 140%;

					a {
						display: block;
						margin-top: 8px;
						color: black;
						text-decoration: underline;
					}
				}

				.price {
					font-size: 28px;
					font-weight: bold;
					letter-spacing: -0.28px;
					margin-top: auto;
				}


				.btn {
					text-transform: none;
				}
			}
		}
	}

	.section.slideshow {
		background-color: #FFFBF5;
		padding-top: 20px;

		@media screen and (max-width: 576px) {
			padding: 16px 32px 40px 32px;
		}

		.section-title {
			color: #344054;
		}

		.slides {
			display: flex;
			justify-content: center;
			position: relative;
			width: 100%;
			height: 45px;
			margin: 40px 0;
			font-family: Didot, serif;
			font-size: 45px;
			letter-spacing: -1px;
			text-align: center;

			@media screen and (max-width: 576px) {
				height: 100px;
				font-size: 32px;
				line-height: 50px;
			}

			.slide {
				position: absolute;
				opacity: 0;
				transition: opacity 1.5s;

				&.visible {
					opacity: 1;
				}
			}
		}
	}

	.section.benefits {

		.benefits-list {
			display: flex;
			flex-direction: column;
			gap: 100px;
			margin: 60px 40px 0 40px;

			@media screen and (max-width: 576px) {
				gap: 70px;
				margin: 60px 16px 0 16px;
			}

			li {
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 48px;

				&:nth-child(2n) {
					flex-direction: row-reverse;
				}

				@media screen and (max-width: 576px) {
					flex-direction: column;

					&:nth-child(2n) {
						flex-direction: column;
					}
				}

				.image {
					img {
						width: 100%;
						height: 100%;
					}
				}

				.description {
					//max-width: 520px;

					.title {
						font-family: Roboto, sans-serif;
						font-size: 28px;
						letter-spacing: -0.28px;
						font-weight: bold;
					}

					.text {
						line-height: 140%;
						margin-top: 22px;
					}
				}
			}
		}

		.btn {
			margin-top: 80px;;
		}
	}

	.section.experience {
		background-color: #FFFBF5;

		.section-content {
			display: flex;
			justify-content: center;
			gap: 80px;
			margin-top: 80px;
			padding: 0 40px;

			@media screen and (max-width: 576px) {
				flex-direction: column;
				width: 100%;
				padding: 0;
				margin-top: 40px;
			}

			.image {
				max-width: 497px;

				img {
					width: 100%;
				}
			}

			.list {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				gap: 20px;
				width: 526px;

				@media screen and (max-width: 576px) {
					gap: 24px;
					width: auto;
					padding: 0 16px;
				}

				li {
					&:empty {
						width: 100%;
						height: 1px;
						background-color: #EADED4;
					}

					h3 {
						font-family: Roboto, sans-serif;
						font-size: 30px;
						white-space: nowrap;

						i {
							font-family: Didot, serif;
							color: #B6A799;
							margin-right: 10px;
						}
					}

					p {
						margin-top: 22px;
						line-height: 140%;
						color: #282D35;

						@media screen and (max-width: 576px) {
							margin-top: 16px;
						}
					}
				}
			}
		}

		.btn {
			margin-top: 80px;;
		}
	}
}