.gift-card-checkout {
	background-color: #F9FAFB;
	line-height: 1.2;
	height: 100% !important;
	min-height: 100dvh;

	@media screen and (max-width: 768px) {
		padding-top: 84px !important;

		.navbar {
			display: none;
		}
	}

	.inner-content {
		max-width: 1050px;
		margin: 0 auto;
	}

	.header {
		position: relative;
		display: flex;
		align-items: center;
		text-align: center;
		margin-top: 40px;

		@media screen and (max-width: 768px) {
			position: fixed;
			left: 0;
			top: 0;
			width: 100%;
			padding: 16px;
			background: white;
			margin-top: 0;
			border-bottom: 1px solid #E4E7EC;
			z-index: 1;
		}

		h1 {
			font-family: Didot, serif;
			font-size: 24px;
			line-height: 1;
			width: 100%;

			@media screen and (max-width: 768px) {
				width: auto;
				margin-left: auto;
				font-size: 18px;
			}
		}

		.back-btn {
			left: 15px;
			top: 3px;
			max-width: 110px;

			&:after {
				content: 'Back';
				margin-left: 10px;
			}

			@media screen and (max-width: 768px) {
				position: static;
				&:after {
					content: '';
				}
			}
		}

		.logo {
			display: none;
			width: 50px;

			@media screen and (max-width: 768px) {
				display: block;
			}
		}
	}

	.checkout-form {
		border-radius: 12px;
		background: white;
		padding: 16px;
		margin: 40px 0;
	}
}