.stylist {
	color: #000;
	padding-bottom: 120px;

	.page-navbar {
		position: relative;
		.back {
			position: absolute;
			left: 0;
			top: 15px;

			svg {
				cursor: pointer;
				width: 14px;
				height: 14px;
			}
		}

		.row {
			margin: auto 20px;
			.anchor {
				font-family: UntitledSans-Regular;
				font-size: 12px;
				letter-spacing: 0.29px;
				padding: 20px 0 0;
				text-align: center;
				text-transform: uppercase;
			}
		}
	}

	.title {
		font-family: SabonLTPro-Italic;
		font-size: 24px;
		line-height: 20px;
		letter-spacing: 0.22px;
		margin: 40px 0 20px;
	}

	.subtitle {
		font-family: UntitledSans-Medium;
		font-size: 18px;
		line-height: 20px;
		letter-spacing: 0.45px;
		margin: 15px 0;
	}

	.content-toggle-btn {
		font-family: UntitledSans-Medium;
		font-size: 16px;
		letter-spacing: 0.48px;
		text-decoration: underline;
		background: transparent;
		color: #000;
		padding: 0;
		text-transform: none;
		cursor: pointer;

		&:hover, &:focus, &:active {
			color: #000;
			background-color: transparent;
			border: none;
		}
	}

	.add-review-btn {
		font-family: UntitledSans-Medium;
		font-size: 16px;
		letter-spacing: 0.52px;
		margin: 10px auto;
		padding: 15px 19px;
		border: 1px solid #000;

		&:before {
			content: '+';
			font-size: 20px;
			line-height: 0;
			position: relative;
			top: 1px;
			right: 3px;
		}
	}

	.contact-btn {
		font-size: 16px;
		letter-spacing: 0.52px;
		text-transform: capitalize;
		padding: 15px 45px;
	}

	p {
		font-family: UntitledSans-Regular;
		font-size: 16px;
		line-height: 29px;
		margin: 0;
	}

	hr {
		border-top: 1px solid #f4f4f4;
		margin: 40px auto;
	}

	.stylist-moodboard {
		max-width: 580px;
		padding: 10px;

		img {
			background-color: #f4f4f4;
			width: 100%;
			height: auto;
			transition: opacity 200ms ease-in-out;

			&.show {
				opacity: 1;
			}
			&.hide {
				opacity: 0;
			}
		}
	}

	.details {
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 100%;
		padding: 0;

		.name {
			font-family: SabonLTPro-Italic;
			font-size: 44px;
			line-height: 50px;
			letter-spacing: 0.44px;
			padding-bottom: 13px;
			order: 1;
		}

		.location {
			font-family: UntitledSans-Regular;
			font-size: 22px;
			line-height: 20px;
			letter-spacing: 2px;
			padding-bottom: 70px;
			text-transform: uppercase;
			order: 2;
		}

		.match-percentage {
			font-family: UntitledSans-Regular;
			font-size: 18px;
			line-height: 20px;
			letter-spacing: 0.69px;
			padding-bottom: 22px;
			order: 3;
		}

		.instagram {
			font-family: UntitledSans-Medium;
			font-size: 18px;
			line-height: 20px;
			letter-spacing: 0.98px;
			padding-bottom: 40px;
			order: 4;

			.col {
				align-items: center;
				display: flex;

				a {
					color: #000;
					display: block;
					height: 30px;
					line-height: 30px;

					span {
						padding-left: 35px;
					}

					.instagram-icon {
						width: 30px;
						height: 30px;
						border: 1px solid #000;
						border-radius: 50%;
						padding: 5px;
						position: absolute;
					}
				}
			}
		}

		.description {
			font-family: UntitledSans-Regular;
			font-size: 18px;
			line-height: 33px;
			letter-spacing: 0.54px;
			max-width: 450px;
			order: 5;
		}
	}

	.work {
		.looks {
			.row {
				max-width: 900px;
			}

			.look {
				flex: 0 0 50%;
				display: flex;
				flex-direction: column;

				.look-name {
					font-family: UntitledSans-Regular;
					font-size: 14px;
					letter-spacing: 0.25px;
					padding: 10px 0;
					order: 1;
					text-transform: capitalize;
				}
				.look-image {
					border: 1px solid #dbdbdb;
					width: 400px;
					height: 400px;
					order: 2;
				}
			}
		}
	}

	.why {
		margin-top: 40px;
	}

	.experience {
		padding-bottom: 45px;
		.row {
			margin-bottom: 20px;
		}

		a {
			color: #000;
			font-weight: 700;
		}
	}

	.expertise {
		display: flex;
		flex-wrap: nowrap;
		overflow: scroll;

		.expertise-item {
			font-family: UntitledSans-Regular;
			max-width: 146px;
			min-width: 115px;
			max-height: 217px;
			font-size: 14px;
			letter-spacing: 1.4px;
			text-align: center;
			text-transform: uppercase;
			background-color: #f4f4f4;
			padding: 14px;
			margin-right: 10px;
			flex-direction: column;
			align-items: center;
			justify-content: space-between;
			display: flex;

			img {
				width: 100%;
				height: auto;
			}
		}
	}

	.stylist-reviews {
		flex-direction: column;
		.reviews {
			padding: 0;
			margin-bottom: 30px;

			.review-row:not(:last-of-type) {
				border-bottom: 1px solid #f4f4f4;
				margin-bottom: 35px;
				padding-bottom: 15px;
			}
		}
	}

	.styling-experience {
		margin-bottom: 20px;
	}

	.matches-section {
		.matches {
			.match-item-container {
				margin-bottom: 30px;
			}

			.match-item {
				margin: 0;

				button {
					bottom: 10px;
				}
			}
		}

		.more-btn {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 225px;
			height: 52px;
			border: 1px solid black;
			background: white;
			border-radius: 4px;
			font-weight: 500;
			cursor: pointer;
			color: black;

			&:after {
				width: 7px;
				height: 7px;
				margin-left: 15px;
				border-top: 1px solid black;
				border-right: 1px solid black;
				transform: rotate(45deg);
				content: '';
			}
		}
	}

	
	@media screen and (max-width: 756px) {
		margin-top: 0;

		p {
			font-size: 18px;
		}

		&.container {
			padding: 0 30px 80px 30px;
		}

		.details {
			border-bottom: 1px solid #f4f4f4;
			padding-bottom: 40px;

			.profile-image {
				text-align: center;
				margin-bottom: 13px;

				img {
					width: 103px;
					height: 103px;
					border-radius: 50%;
				}
			}

			.name, .location {
				text-align: center;
			}

			.name {
				font-size: 32px;
				padding: 0;
			}

			.location {
				font-family: UntitledSans-Regular;
				font-size: 16px;
				padding-bottom: 13px;
			}

			.instagram {
				order: 5;
				margin-top: 15px;
				padding-bottom: 0;
			}

			.description {
				order: 4;
			}
		}

		.work {
			.looks {
				.row {
					flex-direction: column;
				}
				.look {
					flex: 0 0 100%;
					.look-image {
						width: 100%;
						height: auto;
						order: 1;
					}
					.look-name {
						font-family: UntitledSans-Medium;
						font-size: 16px;
						letter-spacing: 0.6px;
						order: 2;
					}
				}
			}
		}

		.expertise {
			overflow-x: scroll;
			flex-wrap: nowrap;

			.expertise-item {
				min-height: 158px;
				min-width: 110px;
				font-size: 12px;
				letter-spacing: 0.52px;
				padding: 7px;
			}
		}

		.styling-experience {
			.experience {
				padding-bottom: 30px;
				margin-bottom: 15px;
				border-bottom: 1px solid #f4f4f4;
			}
		}

		.matches-section {
			.button-container {
				display: flex;
				align-items: center;
			}

			.more-btn {
				margin-bottom: 30px;
			}
		}
	}
}

.book-bar {
	border-top: 1px solid #f4f4f4;
	background-color: #fff;

	.navbar-nav {
		width: 50%;

		.profile-image {
			max-width: 108px;
			.profile-photo {
				width: 78px;
				height: 78px;
				border-radius: 50%;
			}
		}

		.stylist-details {
			display: flex;
			flex-direction: column;
			justify-content: center;

			.name {
				font-family: UntitledSans-Medium;
				font-size: 18px;
				letter-spacing: 0.18px;
			}

			.response-time {
				font-family: UntitledSans-Regular;
				font-size: 14px;
				line-height: 20px;
				letter-spacing: 0.54px;
			}
		}
	}

	.booking-btn-container {
		display: flex;
		flex-direction: row;
		align-items: flex-end;
		justify-content: space-evenly;
		width: 50%;

		.or {
			font-family: UntitledSans-Regular;
			font-size: 12px;
			letter-spacing: 0.39px;
			padding: 10px 0;
		}

		button {
			width: 321px;
			height: 60px;
			margin: 0 12px;
			font-size: 22px;
			letter-spacing: 0.59px;
			font-family: UntitledSans-Medium;
		}
		.gift-card-btn {
			font-family: UntitledSans-Regular;
			font-size: 22px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 4px;
			width: 177px;
			height: 60px;
			border: 1px solid #979797;
			margin: 0 12px;
			color: #000;
		}

		@media screen and (max-width: 768px) {
			width: 100%;
			align-items: center;
			flex-direction: column;

			button, a {
				width: 100%;
			}
			.gift-card-btn {
				width: 100%;
				letter-spacing: 0.46px;
			}
		}
	}
}

.review {
	.reviewer {
		display: flex;
		flex-direction: row;
		align-items: center;
		padding: 0;

		.profile-image {
			width: 72px;
			height: 72px;
			border-radius: 50%;
			overflow: hidden;
			margin-right: 17px;

			img {
				width: 100%;
			}
		}

		.info {
			display: flex;
			flex-direction: column;

			.name {
				font-family: UntitledSans-Medium;
				font-size: 18px;
				letter-spacing: 0.45px;
			}

			.date {
				font-family: UntitledSans-Regular;
				font-size: 16px;
				letter-spacing: 0.4px;
				color: #5a5a61;
			}
		}

		.default-image {
			font-family: UntitledSans-Regular;
			font-size: 21px;
			letter-spacing: 2.64px;
			height: 100%;
			width: 100%;
			background-color: #000;
			color: #fff;
			text-transform: uppercase;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}

	.description {
		font-family: UntitledSans-Regular;
		font-size: 16px;
		line-height: 36px;
		letter-spacing: 0.4px;
		padding: 15px 0;

		img {
			display: block;
			max-width: 200px;
		}
	}

	@media screen and (max-width: 756px) {
		.reviewer {
			.profile-image {
				width: 55px;
				height: 55px;
			}
			.info {
				.name {
					font-size: 16px;
				}
				.date {
					font-size: 12px;
				}
			}
		}

		.description {
			line-height: 30px;
		}
	}
}

.share-bar {
	font-family: UntitledSans-Regular;
	font-size: 16px;
	line-height: 20px;
	letter-spacing: 0.62px;
	background: #f4f4f4;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}