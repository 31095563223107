:root {
    --logo-black: url(https://media-cf.wishi.me/react/landing/wishi_black.svg);
    --icon-cart: url(https://media-cf.wishi.me/react/icons/cart.svg);
    --our-story-header-bg: url(https://media-cf.wishi.me/react/our-story/our-story-bg.jpg);
    --canvas-toolbar-remove: url(https://media-cf.wishi.me/react/icons/canvas-toolbar-remove.png);
    --canvas-toolbar-backward: url(https://media-cf.wishi.me/react/icons/canvas-toolbar-backward.png);
    --canvas-toolbar-forward: url(https://media-cf.wishi.me/react/icons/canvas-toolbar-forward.png);
    --canvas-toolbar-crop: url(https://media-cf.wishi.me/react/icons/canvas-toolbar-crop.png);
    --canvas-toolbar-flip: url(https://media-cf.wishi.me/react/icons/canvas-toolbar-flip.png);
    --canvas-toolbar-flop: url(https://media-cf.wishi.me/react/icons/canvas-toolbar-flop.png);
    --canvas-toolbar-remove-background: url(https://media-cf.wishi.me/react/icons/canvas-toolbar-remove-background.png);
    --canvas-toolbar-undo-remove-background: url(https://media-cf.wishi.me/react/icons/canvas-toolbar-undo-remove-background.png);
    --gifts-header-bg: url(https://media-cf.wishi.me/react/campaigns/gifts/header-bg.png);
    --gifts-karla-1: url(https://media-cf.wishi.me/react/campaigns/gifts/karla.jpg);
    --gifts-karla-2: url(https://media-cf.wishi.me/react/campaigns/gifts/karla2.jpg);
    --gifts-karla-3: url(https://media-cf.wishi.me/react/campaigns/gifts/karla3.png);
    --gifts-karla-4: url(https://media-cf.wishi.me/react/campaigns/gifts/karla4.png);
    --gifts-clea-1: url(https://media-cf.wishi.me/react/campaigns/gifts/clea1.png);
    --gifts-clea-2: url(https://media-cf.wishi.me/react/campaigns/gifts/clea2.png);
    --gifts-clea-3: url(https://media-cf.wishi.me/react/campaigns/gifts/clea3.png);
    --gifts-clea-4: url(https://media-cf.wishi.me/react/campaigns/gifts/clea4.png);
}