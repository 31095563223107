.gifts-landing {
	h1, h2, h3, a {
		font-family: SabonLTStd-Italic;
	}
	a {
		color: #000;
		text-decoration: underline;
		font-size: 24px;
		line-height: 34px;
		letter-spacing: 0;
		&:hover {
			color: #000;
		}
	}
	&.container-fluid {
		display: flex;
		flex-direction: column;
	}

	.header {
		order: 1;
		background-image: var(--gifts-header-bg);
		background-size: cover;
		background-repeat: no-repeat;
		height: 470px;
		.col {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			text-align: center;
			color: #fff;
			position: relative;

			h1 {
				font-size: 80px;
				line-height: 78px;
				letter-spacing: -2.8px;
				max-width: 561px;
			}
			a {
				font-family: UntitledSans-Medium;
				border-radius: 2px;
				font-size: 16px;
				line-height: 35px;
				letter-spacing: 0.8px;
				border: 0;
				height: 50px;
				width: 310px;
				text-decoration: none;
				padding: 8px;
				margin-top: 40px;
			}
		}
	}

	.stylists {
		order: 2;
		padding: 40px 0 151px;
		display: block;
		.col {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			text-align: center;
			color: #000;
			position: relative;

			h3 {
				font-size: 40px;
				line-height: 78px;
				letter-spacing: -1.4px;
			}
			p {
				font-family: UntitledSans-Regular;
				font-size: 18px;
				line-height: 34px;
				letter-spacing: 0;
				max-width: 900px;
			}
		}
		.list {
			padding-top: 45px;
			justify-content: space-evenly;
			div {
				text-align: center;
				img {
					width: 163px;
					height: 163px;
					border-radius: 80px;
				}
				a {
					font-family: SabonLTStd-Italic;
					font-size: 24px;
					line-height: 34px;
					letter-spacing: 0;
					padding-top: 20px;
					display: block;
				}
			}
		}
	}

	.karla, .clea {
		a:after {
			content: "→";
		}
		.slick-slider {
			max-height: 391px;
			.slick-list {
				.slick-track {
					max-width: 1220px;

					.slick-slide {
						.card {
							border: 0;
							background: transparent;
							.card-body {
								width: 284px;
								height: 335px;
								padding: 15px 0 ;
								margin: 0 13px;
								border-radius: 0;
								box-shadow: 0 2px 4px 0 rgba(201, 201, 201, 0.5);
								background: #fff;
								display: flex;
								flex-direction: column;
								align-items: center;
								img {
									object-fit: cover;
									object-position: 50% 0;
									height: 250px;
									width: 250px;
								}
								a {
									font-family: SabonLTPro-Italic;
									font-size: 24px;
									line-height: 34px;
									letter-spacing: 0;
									color: #44474b;
									padding-top: 22px;
									margin: 0;
									text-align: center;
									&:after {
										content: '';
									}
								}
							}
						}
					}
				}
			}
		}
	}

	.karla {
		order: 3;
		background-color: #f8f8f8;
		padding: 53px 0;
		.col-5, .col-sm-2, .col-12 {
			padding: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			height: 452px;
		}
		h1 {
			font-size: 80px;
			line-height: 78px;
			letter-spacing: -2.8px;
			white-space: pre-line;
			padding-bottom: 31px;
		}
		h3 {
			font-family: UntitledSans-Regular;
			font-size: 20px;
			line-height: 34px;
			letter-spacing: 0;
			max-width: 420px;
		}
		p {
			font-family: SabonLTPro-Italic;
			font-size: 20px;
			line-height: 34px;
			letter-spacing: 0;
		}
		.image {
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
			&.main {
				background-image: var(--gifts-karla-1);
				height: 452px;
			}
			&.single {
				background-image: var(--gifts-karla-2);
				outline: 12px solid #f8f8f8;
			}
			&.left {
				background-image: var(--gifts-karla-3);
				outline: 12px solid #f8f8f8;
			}
			&.right {
				background-image: var(--gifts-karla-4);
				outline: 12px solid #f8f8f8;
			}
		}
		.container-fluid {
			height: 100%;
			.row {
				height: 50%;
			}
		}
		.items {
			order: 4;
			padding-top: 53px;
		}
	}

	.clea {
		order: 4;
		padding: 53px 0;
		.col-sm-4, .col-12 {
			padding: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			height: 452px;
		}
		.order-sm-3 {
			max-width: 370px;
		}
		h1 {
			font-size: 80px;
			line-height: 78px;
			letter-spacing: -2.8px;
			white-space: pre-line;
			padding-bottom: 31px;
		}
		h3 {
			font-family: UntitledSans-Regular;
			font-size: 20px;
			line-height: 34px;
			letter-spacing: 0;
			max-width: 420px;
		}
		p {
			font-family: SabonLTPro-Italic;
			font-size: 20px;
			line-height: 34px;
			letter-spacing: 0;
		}
		.image {
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
			&.main {
				background-image: var(--gifts-clea-1);
				height: 524px;
				max-width: 100%;
				background-position: top center;
			}
			&.single {
				background-image: var(--gifts-clea-4);
				outline: 10px solid #fff;
				height: 248px;
				width: 370px;
			}
			&.left {
				background-image: var(--gifts-clea-2);
				outline: 10px solid #fff;
			}
			&.right {
				background-image: var(--gifts-clea-3);
				outline: 10px solid #fff;
			}
			&.right, &.left {
				height: 100%;
				width: 178px;
			}
		}
		.container-fluid {
			height: 100%;
			.row{
				&:first-of-type {
					height: 277px;
				}
			}
		}
		.items {
			order: 5;
			padding-top: 53px;
		}
	}

	.cards {
		order: 6;
		.cards-content {
			background: #21365f;
			height: 574px;
			display: flex;
			justify-content: center;
			align-items: center;
			text-align: center;

			h2 {
				font-size: 48px;
				line-height: 61px;
				letter-spacing: -1.68px;
				text-align: center;
				color: #fff;
				max-width: 370px;
				margin-bottom: 38px;
			}

			a {
				font-family: UntitledSans-Medium;
				border-radius: 2px;
				font-size: 14px;
				line-height: 26px;
				letter-spacing: 0.7px;
				border: 0;
				height: 55px;
				width: 236px;
				text-decoration: none;
			}

			.image {
				position: relative;
				height: 100%;
				width: 60%;
				img {
					height: 260px;
					border-radius: 10px;
					&.card1 {
						position: absolute;
						bottom: 60px;
						right: 70px;
						transform: translate(-50%, -50%) rotate(-7deg);
						z-index: 1;
					}
					&.card2 {
						background-color: #F6F0F1;
						border-radius: 10px;
						position: absolute;
						right: -25%;
						bottom: -70px;
						transform: translate(-50%, -50%) rotate(5deg);
						z-index: 2;
					}
				}
			}
		}
	}

	.perfect {
		order: 5;
		h1 {
			font-size: 60px;
			line-height: 78px;
			letter-spacing: -2.1px;
			color: #1a1a1a;
			padding-bottom: 53px;
		}
		.container-fluid {
			padding: 0;
		}

		.perfect-item {
			font-family: UntitledSans-Regular;
			padding: 0 28px;
			img {
				width: 100%;
				height: 350px;
				object-fit: cover;
			}
			h6 {
				font-size: 24px;
				line-height: 78px;
				letter-spacing: -0.84px;
			}
			p {
				font-size: 20px;
				color: #44474b;
			}
			a:after {
				content: "→";
			}
		}

		.slick-slider .slick-list .slick-track {
			padding-bottom: 124px;
		}
	}

	.faq {
		order: 6;
		padding: 177px 0 190px;
		h3 {
			padding-bottom: 43px;
			font-size: 30px;
			line-height: 44px;
			letter-spacing: 0;
		}
		.faq-list {
			flex-direction: column;
			padding: 0;
			.question {
				margin: 0 15px;

				.card, .card-header, .card-body, button {
					padding: 0;
					border: 0;
					border-radius: 0;
					background-color: transparent;
				}
				.card {
					border-top: 1px solid #ACACAC;
					padding: 35px 0 35px 40px;
					&.opened {
						.card-header:after {
							transform: rotate(-45deg);
						}
					}
					.card-header:after {
						position: absolute;
						content: '';
						display: inline-block;
						width: 15px;
						height: 15px;
						border-right: 2px solid #919191;
						border-top: 2px solid #919191;
						transform: rotate(135deg);
						transition: transform .5s;
						right: 15px;
						top: 50px;
					}
				}
				button {
					font-family: SabonLTPro-Italic;
					font-size: 20px;
					line-height: 40px;
					letter-spacing: 0;
					color: #000;
					text-align: left;
					width: 100%;
					&:hover, &:focus {
						text-decoration: none;
						opacity: .6;
						box-shadow: none;
					}
				}
				.card-body {
					font-family: UntitledSans-Regular;
					font-size: 16px;
					line-height: 33px;
					letter-spacing: 0;
					padding-top: 10px;
					div a {
						color: #fa5649;
						&:hover {
							color: #fa5649;
							opacity: .7;
						}
					}
				}
			}
		}
	}

	.slick-slider {
		.slick-list {
			overflow: visible;
			.slick-track {
				display: flex;
				justify-content: center;
			}
			.slick-slide {
				div:focus {
					outline: none;
				}
			}
		}
	}

	@media screen and (max-width: 768px) {
		a {
			font-size: 24px;
		}
		.navbar {
			height: 55px;
			.logo {
				height: 40px;
			}
		}
		.header {
			height: 213px;
			.col {
				h1 {
					font-size: 40px;
					line-height: 50px;
					letter-spacing: -0.9px;
					max-width: 88%;
				}
			}
		}
		.stylists {
			padding: 35px 0;
			.col {
				max-width: 88%;
				margin: 0 auto;
				h3 {
					font-size: 24px;
					line-height: 31px;
					letter-spacing: -0.7px;
				}
				p {
					font-size: 16px;
					line-height: 29px;
				}
			}
			.list {
				div a {
					padding-bottom: 35px;
					font-size: 24px;
				}
			}
		}
		.karla, .clea {
			background: #fff;
			.col-12 {
				height: auto;
				justify-content: flex-start;
				padding: 0 53px;
			}
			h1 {
				font-size: 40px;
				line-height: 44px;
				letter-spacing: -0.9px;
			}
			h3, p {
				display: none;
			}
			a:after {
				content: '';
			}
			.slick-slider {
				.slick-list {
					.slick-track {
						max-width: none;
					}
					.slick-slide {
						max-width: 284px;
						margin: 0 15px;
						.card .card-body {
							margin: 0 auto;
						}
					}
				}
			}
		}
		.karla {
			padding: 0;
			.image.main {
				max-height: 289px;
			}
			.items {
				a {
					text-align: center;
					padding-top: 26px;
				}
			}
		}
		.clea {
			h1 {
				padding-bottom: 0;
			}
			.items {
				padding-top: 0;
			}
		}
		.perfect {
			h1 {
				font-size: 40px;
				line-height: 44px;
				letter-spacing: -0.9px;
				padding-bottom: 44px;
			}
			.perfect-item {
				padding: 0 0 29px;
				p {
					font-size: 16px;
					line-height: 29px;
					letter-spacing: 0;
					max-width: 88%;
				}
				a {
					display: block;
					text-align: center;
					&:after {
						content: '';
					}
				}
			}
			.slick-slider .slick-list .slick-track {
				max-height: 1800px;
				padding-bottom: 0;
			}
		}
		.cards {
			.cards-content {
				flex-direction: column;
				margin: 0 -15px;
				h2 {
					font-size: 40px;
					line-height: 50px;
					letter-spacing: -1.4px;
				}
				a {
					width: 100%;
				}
				.image {
					width: 100%;
					height: 50%;
					img {
						height: 142px;
						&.card1 {
							left: 35%;
							bottom: 0;
						}
						&.card2 {
							left: 60%;
						}
					}
				}
			}
		}
		.faq {
			padding: 35px 0 35px 0;
			h3 {
				padding-bottom: 20px;
				font-size: 24px;
				line-height: 34px;
				letter-spacing: 1.31px;
				text-align: center;
			}
			.faq-list {
				.question {
					.card {
						padding-left: 0;
						.card-body {
							max-width: 88%;
						}
					}
				}
			}
		}
	}
}

.gifts-shopping {
	z-index: 1060;
	&.mobile-collapse-open {
		position: fixed;
	}

	&.container-fluid {
		padding: 57px 0 45px 36px;
	}

	.loader {
		position: fixed;
	}

	.title {
		h1 {
			font-family: SabonLTPro-Italic;
			font-size: 40px;
			letter-spacing: 2.17px;
			text-align: center;
		}
		h3 {
			font-family: UntitledSans-Regular;
			font-size: 16px;
			line-height: 29px;
			max-width: 708px;
			margin: 0 auto;
			text-align: center;
			padding: 26px 0 39px;
		}
	}

	.mobile-side-collapse {
		padding: 55px 25px 55px 45px;
		&.hide {
			.filter-btns {
				left: -100vw;
			}
		}
	}

	.content {
		.filters {
			flex-direction: column;
			max-width: 320px;
			label {
				font-family: UntitledSans-Medium;
				font-size: 18px;
				letter-spacing: 0.98px;
			}
			.filter {
				label{
					font-family: UntitledSans-Regular;
					font-size: 18px;
					line-height: 35px;
					&:before {
						border: 1px solid #000;
						width: 20px;
						height: 20px;
						margin-right: 10px;
					}
				}
			}
		}

		.results {
			display: flex;
			flex-wrap: wrap;
			padding: 0;
			max-width: 1020px;

			.infinite-scroll-component {
				display: flex;
				flex-wrap: wrap;
			}

			.filters-bar {
				max-height: 32px;
				.dropdown-menu {
					transform: none !important;
					top: 100% !important;
				}

				&.mobile {
					display: flex;
					margin: 26px auto 12px;
					flex-wrap: nowrap;
					overflow-y: scroll;
					max-height: none;
					height: 40px;
					.badge {
						font-family: UntitledSans-Regular;
						font-size: 14px;
						height: 26px;
						padding: 5px 10px;
						border: 1px solid #979797;
						margin-right: 10px;
						border-radius: 4px;
						&.selected {
							background: #000;
							color: #fff;
							border-color: #000;
						}
					}
				}
				.filter-icon {
					width: 23px;
					height: 15px;
					cursor: pointer;
				}
			}

			.filter-badges {
				padding-top: 10px;
				.badge {
					font-family: UntitledSans-Regular;
					font-size: 16px;
					position: relative;
					margin: 5px 10px 5px 0;
					padding: 5px 20px 5px 8px;

					.close {
						top: 10px;
						right: 10px;
						left: auto;
						width: 7px;
						height: 7px;
						&:before, &:after {
							height: 10px;
							left: auto;
							right: 0;
							width: 1px;
						}
					}
				}
			}

			.campaign-item {
				width: 300px;
				height: 525px;
				margin: 15px 25px 40px 15px;
				position: relative;
				display: inline-flex;
				flex-direction: column;
				align-items: flex-start;
				justify-content: flex-start;
				cursor: pointer;
				background-color: #fff;
				color: #000;

				&:hover {
					.image-container {
						.image-overlay, .cart-icon, &:before {
							display: flex;
							text-align: center;
						}
					}
				}

				.image-container {
					&:before {
						content: '';
						background-color: #fff;
						border-radius: 50%;
						width: 46px;
						height: 46px;
						position: absolute;
						z-index: 1;
						right: 10px;
						top: 10px;
						display: none;
					}

					.cart-icon {
						position: absolute;
						right: 19px;
						top: 17px;
						z-index: 10;
						cursor: pointer;
						display: none;
						&.add:after {
							left: 10px;
						}
						&.added:after {
							left: 12px;
						}
					}

					.image-overlay {
						background-color: rgba(0,0,0,0.5);
						width: 100%;
						height: 353px;
						position: absolute;
						color: #fff;
						display: none;
						align-items: center;
						justify-content: center;
						font-family: UntitledSans-Medium;
						font-size: 20px;
					}
				}

				img {
					height: 353px;
					width: 100%;
					padding: 8px;
					object-fit: contain;
					border: 1px solid #dbdbdb;
				}

				span {
					width: 100%;
					p {
						font-family: UntitledSans-Regular;
						font-size: 14px;
						margin: 0;
						display: block;
					}
					.brand {
						padding: 18px 0 0;
						text-transform: uppercase;
					}
					.name {
						color: #44474b;
						padding: 20px 0 15px;
					}
					.price {
						font-family: UntitledSans-Medium;
						line-height: 20px;
						padding-top: 5px;
						display: inline-block;
						padding-right: 7px;
						&.line-through {
							font-family: UntitledSans-Regular;
							text-decoration: line-through;
						}
					}
					.picked {
						color: #8e8e93;
						font-size: 12px;
						letter-spacing: 0.6px;
						padding: 10px 0;
					}
					.out-of-stock {
						font-size: 14px;
						color: #f50101;
						letter-spacing: 0.22px;
					}
				}
				.cart-link {
					display: none;
					font-family: UntitledSans-Medium;
					font-size: 14px;
					line-height: 20px;
					width: 100%;
					text-decoration: underline;
				}
			}

			.dropdown-toggle {
				padding: 0;
				margin-right: 34px;
				&:after, &:active:after, &:focus:after {
					width: 0;
					height: 0;
					border-left: 5px solid transparent;
					border-right: 5px solid transparent;
					border-top: 5px solid #000;
					transform: none;
				}

				.dropdown-item {
					font-family: UntitledSans-Regular;
					font-size: 18px;
					line-height: 32px;
					padding-left: 10px;
				}
			}

			.sort {
				.dropdown-toggle {
					font-family: UntitledSans-Regular;
					padding: 0;
					margin-right: 10px;
				}

				text-align: right;
			}
		}
	}

	@media screen and (max-width: 576px) {
		&.container-fluid {
			padding: 0 25px;
		}

		.content {
			.results {
				.infinite-scroll-component {
					justify-content: space-between;
					padding: 0 15px 50px;
				}
				.filter-badges {
					padding: 0 15px;
				}
				.campaign-item {
					width: 50%;
					height: 310px;
					margin: 12px 0 12px;
					align-items: center;
					max-width: calc(50% - 12px);
					img {
						max-height: 200px;
					}
					.image-container {
						.cart-icon {
							display: none;
						}
						.image-overlay {
							display: flex;
							background-color: transparent;
							span {
								display: none;
							}
						}
						&:before {
							display: none;
						}
					}
					span {
						.brand {
							padding-top: 7px;
							max-width: 100%;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
						}
					}
					.cart-link {
						display: block;
					}
					&:hover {
						.image-container {
							.cart-icon, &:before {
								display: none;
							}
						}
					}
				}
				.sort {
					padding-right: 15px;
					.dropdown-toggle {
						font-size: 14px;
						margin-right: 0;
					}
				}
			}
		}
	}
}

.gifts-mobile-filters {
	max-width: 300px;

	&.container-fluid {
		max-width: 100%;
		max-height: 100%;
		overflow-y: scroll;
		overflow-x: hidden;
	}

	.filters-container {
		overflow: scroll;
		flex-direction: column;
	}

	.filters-title {
		font-family: UntitledSans-Regular;
		font-size: 16px;
		line-height: 35px;
	}

	.filters-list {
		.filter-row {
			border: none;
			padding: 15px 0;
			border-bottom: 1px solid #f4f4f4 !important;

			.filter {
				cursor: pointer;
				font-family: UntitledSans-Medium;
				font-size: 18px;
				margin: 0;
				line-height: 35px;
				letter-spacing: 0.89px;
				padding: 0;
				background: transparent;
				border: none
			}

			.expand-sign {
				&:after {
					content: '+';
					font-size: 18px;
					width: 10px;
					position: absolute;
					right: 0;
					top: 13px;
					text-align: center;
				}
			}

			&.active {
				.expand-sign:after {
					content: '-';
				}
			}

			.card-body {
				padding: 0;
				max-height: 350px;
				overflow-y: scroll;
			}
		}
	}

	.filter-item {
		padding: 0;
		.filter-label {
			font-family: UntitledSans-Regular;
			font-size: 16px;
			letter-spacing: 0.89px;
			line-height: 40px;
			padding: 0;

			&:before {
				width: 20px;
				height: 20px;
				position: absolute;
				right: 0;
				margin: 0;
				border: 1px solid #000;
			}
			&:after {
				left: auto;
				top: 14px;
				right: 7px;
			}
		}

	}

	.show-all {
		padding: 0;
		margin-top: 10px;
		text-decoration: underline;
		cursor: pointer;
	}

	.filter-btns {
		position: fixed;
		left: 0;
		bottom: 0;
		width: 100vw;
		padding: 10px 37px;
		display: flex;
		align-items: center;
		margin: 0;
		background: #fff;
		.clear {
			font-family: UntitledSans-Medium;
			font-size: 18px;
			text-decoration: underline;
			cursor: pointer;
		}
		.btn {
			font-size: 16px;
			padding: 12px;
			width: 100%;
			text-transform: none;
		}
	}
}