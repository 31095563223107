.login-page {
	margin-top: 100px;

	@media screen and (max-width: 576px) {
		margin-top: 0;
	}

	.modal-header  {
		flex-direction: column;
		align-items: center;
	}

	button.close {
		display: none;
	}
}