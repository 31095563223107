
@import './bootstrap-override.scss';
@import './media.scss';

@font-face {
  font-family: WishiIcons;
  src: url(./assets/fonts/WishiIcons/wishi-icons.woff);
}

@font-face {
  font-family: "UntitledSans";
  font-weight: 600;
  src: url(./assets/fonts/UntitledSans/UntitledSans-Bold.otf) format("opentype");
}
@font-face {
  font-family: "UntitledSans";
  font-weight: 600;
  font-style: italic;
  src: url(./assets/fonts/UntitledSans/UntitledSans-BoldItalic.otf) format("opentype");
}
@font-face {
  font-family: "UntitledSans";
  font-weight: 500;
  src: url(./assets/fonts/UntitledSans/UntitledSans-Medium.otf) format("opentype");
}
@font-face {
  font-family: "UntitledSans";
  font-weight: 500;
  font-style: italic;
  src: url(./assets/fonts/UntitledSans/UntitledSans-MediumItalic.otf) format("opentype");
}
@font-face {
  font-family: "UntitledSans";
  font-weight: 400;
  src: url(./assets/fonts/UntitledSans/UntitledSans-Regular.otf) format("opentype");
}
@font-face {
  font-family: "UntitledSans";
  font-weight: 400;
  font-style: italic;
  src: url(./assets/fonts/UntitledSans/UntitledSans-RegularItalic.otf) format("opentype");
}



/* --- Legacy support --- */

/*
  These fonts are used on old app pages.
  Current design system specs are as follows:
  1. General content font is UntitledSans
  2. Title font is LinotypeDidotRoman
*/

@font-face {
  font-family: "Sabon";
  font-weight: 400;
  src: url("./assets/fonts/Sabon Italic/Linotype - SabonLTStd-Italic.otf") format("opentype");
}

@font-face {
  font-family: "Sabon";
  font-style: italic;
  font-weight: 400;
  src: url("./assets/fonts/Sabon Italic/Linotype - SabonLTPro-Italic.otf") format("opentype");
}

@font-face {
  font-family: "Didot";
  src: url(./assets/fonts/Didot/Didot.ttf) format("truetype");
}

@font-face {
  font-family: "Didot";
  font-weight: bold;
  src: url(./assets/fonts/Didot/Didot-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Didot";
  font-style: italic;
  src: url(./assets/fonts/Didot/Didot-Italic.ttf) format("truetype");
}

@font-face {
  font-family: "UntitledSans-Light";
  font-weight: 300;
  src: url(./assets/fonts/UntitledSans/UntitledSans-Light.otf) format("opentype");
}
@font-face {
  font-family: "UntitledSans-Light";
  font-weight: 300;
  font-style: italic;
  src: url(./assets/fonts/UntitledSans/UntitledSans-LightItalic.otf) format("opentype");
}

@font-face {
  font-family: "UntitledSans-Medium";
  src: url(./assets/fonts/UntitledSans/UntitledSans-Medium.otf) format("opentype");
}

@font-face {
  font-family: "UntitledSans-Regular";
  src: url(./assets/fonts/UntitledSans/UntitledSans-Regular.otf) format("opentype");
}

@font-face {
  font-family: "SabonLTStd-Italic";
  src: url("./assets/fonts/Sabon Italic/Linotype - SabonLTStd-Italic.otf") format("opentype");
}

@font-face {
  font-family: "SabonLTPro-Italic";
  src: url("./assets/fonts/Sabon Italic/Linotype - SabonLTPro-Italic.otf") format("opentype");
}

/* --- Legacy support END--- */



body, h1, h2, h3, ul, p {
  all: unset;
  display: block;
  margin: 0;
  padding: 0;
  font-family: UntitledSans, sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  list-style: none;
}

a {
  color: inherit;

  &:hover {
    color: inherit;
  }
}

.no-padding {
  padding: 0 !important;
}

.close {
  position: absolute;
  left: 45px;
  top: 20px;
  width: 23px;
  height: 23px;
  opacity: 1;
  cursor: pointer;
  z-index: 990;

  &:hover {
    opacity: 1;
  }

  &:before, &:after {
    position: absolute;
    left: 15px;
    content: '';
    height: 100%;
    width: 1px;
    background-color: #000;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }

  @media screen and (max-width: 576px) {
    left: 10px;
    top: 10px;
  }
}

.back-btn {
  position: absolute;
  top: 32px;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2;
  letter-spacing: 0.3px;
  cursor: pointer;
  z-index: 990;

  &:before {
    position: relative;
    content: '';
    display: inline-block;
    width: 10px;
    height: 10px;
    border-right: 2px solid black;
    border-top: 2px solid black;
    transform: rotate(225deg);
  }

  &:after {
    content: 'Back';
  }

  &.no-text {
    &:after {
      content: '';
    }
  }

  @media screen and (max-width: 576px) {
    &:after {
      content: '';
    }
  }
}

.watermark {
  background-image: var(--logo-black);
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  margin: 10px;
  width: 40px;
  height: 30px;
  opacity: .2;
}

.cart-icon {
  background-image: var(--icon-cart);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  min-height: 28px;
  min-width: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  letter-spacing: 0.35px;
  padding-top: 7px;

  &.add, &.added {
    &:before {
      content: '';
      width: 15px;
      height: 15px;
      background: #000;
      border-radius: 7px;
      position: relative;
      bottom: -7px;
      left: 0;
    }
  }
  &.add {
    cursor: pointer;
    &:after {
      content: '+';
      color: #fff;
      font-size: 13px;
      position: absolute;
      bottom: -6px;
      left: 6px;
    }
  }
  &.added {
    &:after {
      content: '';
      position: absolute;
      bottom: 1px;
      left: 9px;
      display: flex;
      width: 4px;
      height: 7px;
      border: solid #fff;
      border-width: 0 1px 1px 0;
      transform: rotate(45deg);
    }
  }
  &.disabled {
    opacity: .3;
    cursor: default;
  }
}

.top-btn {
	font-size: 14px;
	letter-spacing: 0.11px;
	position: fixed;
	bottom: 0;
	right: 81px;
	width: 105px;
	height: 53px;
	background-color: #000;
	color: #fff;
	display: flex;
	justify-content: center;
	align-items: flex-end;
	padding: 9px;
	cursor: pointer;
	transition: bottom 200ms ease-in-out;

	&:before {
		position: absolute;
		top: 13px;
		content: '';
		display: inline-block;
		width: 8px;
		height: 8px;
		border-right: 3px solid #fff;
		border-top: 3px solid #fff;
		transform: rotate(315deg);
	}

	&.show {
		bottom: 0;
	}
	&.hide {
		bottom: -53px;
	}
}

.magnifier {
  width: 12px;
  height: 12px;
  right: 16px;
  border-radius: 100%;
  border: 1px solid #000;
  background: #fff;
  display: block;
  position: relative;
  padding: 0;

  &:before {
    content: '';
    width: 1px;
    height: 8px;
    position: absolute;
    right: -2px;
    top: 7px;
    display: block;
    background-color: #000;
    transform: rotate(-45deg);
  }
}

.arrow {
  position: absolute;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2;
  letter-spacing: 0.3px;
  cursor: pointer;
  z-index: 990;

  &:after {
    position: relative;
    content: '' !important;
    display: inline-block;
    width: 10px;
    height: 10px;
    border-right: 2px solid black;
    border-top: 2px solid black;
    transform: rotate(45deg);
    transition: transform .3s;
  }
  &.left {
    &:after {
      transform: rotate(225deg);
    }
  }
  &.down {
    &:after {
      transform: rotate(135deg);
    }
  }
  &.up {
    &:after {
      transform: rotate(-45deg);
    }
  }
}