.mobile-side-collapse {
	position: fixed;
	top: 0;
	z-index: 9999;
	width: 100%;
	height: 100dvh;
	background: #fff;
	transition: all 300ms ease-out;
	overflow: hidden;

	&.show {
		left: 0;
	}
	&.hide {
		left: -100vw;
	}

	.coupon, .credit-card, .billing {
		display: flex;
		flex-direction: column;
		height: 100%;

		.promotion-code {
			height: 100%;
			input {
				height: 50px;
			}
		}

		.error {
			font-family: UntitledSans-Regular;
			font-size: 14px;
			letter-spacing: 0.35px;
			color: #e02020;
		}

		.title {
			font-family: UntitledSans-Medium;
			font-size: 18px;
			letter-spacing: 0.39px;
			padding: 25px 0 40px;
			text-align: center;
		}

		.disclaimer {
			font-family: UntitledSans-Regular;
			font-size: 18px;
			line-height: 27px;
			text-align: center;
			letter-spacing: 0.39px;
			transition: height 200ms ease-in-out;
			overflow: hidden;
			color: #000;
			margin-top: 39px;
			white-space: pre-wrap;

			&.show {
				height: 105px;
			}
			&.hide {
				height: 0;
			}
		}

		.button-container {
			display: flex;
			justify-content: center;
			width: 100%;
			margin-top: auto;
			margin-bottom: 15px;

			button {
				width: 88%;
				font-weight: 600;
			}
		}
	}

	.billing {
		.billing-form {
			padding: 0 15px;
		}
		#input {
			padding: 0;
		}
	}

	.credit-card {
		display: flex;
		flex-direction: column;
		height: 100dvh;
	}
}