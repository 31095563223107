@keyframes toastIn {
	0% {
		opacity: 0;
		/*transform: var(--hiddenState);*/
	}
	100% {
		opacity: 1;
		/*transform: var(--visibleState);*/
	}
}

@keyframes toastOut {
	0% {
		opacity: 1;
		/*transform: var(--visibleState);*/
	}
	100% {
		opacity: 0;
		/*transform: var(--hiddenState);
*/
	}
}

.not-bootstrap-toast {
	--top: 108px;
	--hiddenState: translate(-50%, 100%);
	--visibleState: translate(-50%, calc(0px - var(--top)));

	display: flex;
	position: fixed;
	bottom: 0;
	left: 50%;
	gap: 16px;
	/*transform: var(--hiddenState);*/
	transform: var(--visibleState);
	background: black;
	color: white;
	padding: 16px;
	border-radius: 4px;
	font-size: 18px;
	line-height: 1;
	opacity: 0;
	z-index: 1000000;
	animation: toastOut 200ms;

	&.visible {
		opacity: 1;
		transform: var(--visibleState);
		animation: toastIn 200ms;
	}

	.undo {
		padding: 16px 0 16px 16px;
		margin: -16px 0;
		border-left: 1px solid rgba(255,255,255, 0.5);
		cursor: pointer;
	}
}