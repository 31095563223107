.stripe-error-message {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 40px;

	> div {
		padding: 20px;
		background: #f0f0f0;
		border-radius: 4px;
		max-width: 700px;
		text-align: center;
		line-height: 1.2;
	}

}