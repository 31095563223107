.page {
	height: 100%;
	&.with-header {
		padding-top: 0;
		//overflow-x: hidden;
	}

	&.fixed-header {
		padding-top: 70px;

	}

	&.no-footer {
		height: 100dvh;
	}

	&.chat{
		padding-top: 70px;
		display: flex;
		height: 100dvh;
		background-color: #f4f4f4;

		@media screen and (max-width: 780px) {
			.container {
				padding: 0;
			}
		}
	}

	@media screen and (max-width: 576px){
		&.fixed-header {
			padding-top: 55px;
		}
	}
}
