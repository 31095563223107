.reviewReader-modal{
			font-family: UntitledSans-Regular;  
			.ratingStar{
				position: relative;
				top:13px;
				margin: 0;
				left: -5px;
				display: block;
				height: 22.02px;
				background-image: url("data:image/svg+xml,%3csvg width='35' height='23' viewBox='0 0 24 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M23.8025 8.67183C23.7213 8.42701 23.5051 8.24859 23.2448 8.21162L18.0007 7.46592C16.7052 7.2817 15.5817 6.47545 14.9924 5.30709L12.6628 0.688423C12.5465 0.457622 12.3062 0.311523 12.0432 0.311523C11.7802 0.311523 11.54 0.457622 11.4236 0.688423L9.09395 5.30715C8.50466 6.47548 7.38117 7.2817 6.08567 7.46592L0.841633 8.21162C0.5814 8.24859 0.365115 8.42701 0.283853 8.67178C0.202545 8.9166 0.270356 9.18531 0.458724 9.36495L4.20099 12.9346C5.16841 13.8574 5.61083 15.2025 5.38006 16.5194L4.50065 21.5377C4.45614 21.7914 4.56274 22.0478 4.77553 22.1991C4.8959 22.2846 5.03848 22.3282 5.18175 22.3282C5.29175 22.3282 5.40213 22.3025 5.5032 22.2505L10.2133 19.8274C11.3617 19.2366 12.7247 19.2366 13.8731 19.8274L18.5829 22.2505C18.8158 22.3703 19.0979 22.3503 19.3106 22.199C19.5234 22.0478 19.6301 21.7914 19.5856 21.5377L18.706 16.5197C18.4751 15.2027 18.9176 13.8574 19.8851 12.9345L23.6277 9.3649C23.816 9.18531 23.8839 8.9166 23.8025 8.67183Z' fill='%23F88379'/%3e%3c/svg%3e");
			}
			.clientName{
				display: block;
				position: relative;
				font-size: 22px;
				font-weight: 500;
				line-height: 26px;
				width:90%;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space:nowrap;
				font-family: UntitledSans-Medium;  
			}
			.stylistName{
				display: block;
				position: relative;
				font-size: 16px;
				font-weight: 500;
				line-height: 25px;
				margin-top: 15px;
				font-family: UntitledSans-Medium; 
				@media screen and (max-width: 450px) {
					margin-top: 15px;
				} 
				
			}
			.reviewDate{
				position: relative;
				float:right;
				font-size: 14px;
				line-height: 24px;
				color: #5A5A61;
				margin-right: 40px;
				@media screen and (max-width: 450px) {
					margin-right:22px;
					font-size: 15px;
				}
			
			}
			.name-date-wrapper{
				display: flex;
				flex-direction: row;
				position: relative;
			}
			.name-wrapper{
				flex:80%;
				@media screen and (max-width: 450px) {
					flex:20%;
				}
			}
			.cardText{

				position: relative;
				top:30px;
				height:230px;
				display:block;
				overflow: hidden; 
				word-break: keep-all;
				overflow-wrap: break-word;
				font-size: 16px;
				text-overflow: ellipsis;
				line-height: 25px;
				color:#212529;
                overflow-y: scroll;
				padding-right: 20px;

				@media screen and (max-width: 450px) {
					height: 345px;
				}
			
			}
            .modal-body{
                padding:45px 0 50px 40px;
				@media screen and (max-width: 450px) {
					padding: 0px 0 0 10px;
				}
            }
            .modal-content{
                top:95px;
				left:-80px;
				height: 500px;
				width: 800px;
				padding: 20px 7px 0 25px;
				@media screen and (max-width: 450px) {
					top:53px;
					height: 551px;
					width: 326px;
					left:calc(16px + (100vw - 375px)/2);
					padding: 25px 6px 30px 30px;
				}
            }
			a{
				&.black{
					font-family: UntitledSans-Medium;  
					color: #000000;
					text-decoration: underline;
				}
			}
}
button.close{
	@media screen and (max-width: 450px) {
		top:3px !important;
		left:8px !important;
	}
}
