.favorite-button {
	width: 38px;
	height: 38px;
	font-size: 20px;
	position: absolute;
	top: 11px;
	right: 10px;
	z-index: 10;
	cursor: pointer;
	color: #000;
	transition: width 200ms ease-in-out;
	overflow: hidden;
	padding: 5px 9px;

	p {
		font-family: UntitledSans-Medium;
		font-size: 16px;
		letter-spacing: 0.89px;
		display: inline-block;
		position: absolute;
		left: 42px;
		width: 140px;
		top: 6px;
		margin: 0;
		color: #fff;
	}
	&.on {
		color: #F46C67;
	}
	&:hover {
		color: #F46C67;
	}
	&.expandable {
		&:hover {
			background: rgba(0, 0, 0, 0.6);
			width: 188px;
		}
	}
	@media screen and (max-width: 576px){
		&.expandable {
			&:hover {
				width: 38px;
			}
		}
	}
}