.quiz {
	height: 100dvh;
	overflow: hidden;
	background: #f4f4f4;

	.wishi-chat {
		background: #f4f4f4;
		width: 600px;
		margin: 0 auto;
		height: 100%;
	}

	@media screen and (max-width: 576px) {
		.wishi-chat {
			height: 100dvh;
			width: 100dvw;
		}
	}
}

.quiz-welcome {
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100vh;

	&.container {
		max-width: 400px;
	}

	.title {
		font-family: UntitledSans-Medium;
		font-size: 32px;
		width: 100%;
	}

	.titleInstagram{
		font-family: UntitledSans-Medium;
		font-size: 32px;
		width: 100%;
	}

	.confetti {
		position: absolute;
		height: 100%;
		top: 0;
		width: 25%;
		&.right {
			right: 0;
		}
		&.left {
			left: 0;
		}
	}

	a {
		font-family: UntitledSans-Medium;
		width: 88%;
		margin: 0 auto;
		max-width: 400px;
		background-color: #F88379;
		color: #fff;
		display: block;
		font-size: 16px;
		border-radius: 4px;
		padding: 15px 0;
		letter-spacing: 1.1px;
		text-transform: uppercase;
		line-height: 20px;
		&:hover {
			background-color: #FA948D;
			color: #fff;
		}
	}
}