.google-login {
	button {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 10px;
		width: 100%;
		height: 42px;
		font-size: 14px;
		background: white;
		color: #4A4A4A;
		border: 1px solid #212529;
		border-radius: 100px;
		cursor: pointer;

		&[disabled] {
			opacity: 0.8;
			pointer-events: none;
		}

		img {
			width: 20px;
		}

		@media screen and (max-width: 576px) {
			height: 56px;
			border: 1px solid #484848;
			color: #5A6676;
			font-size: 16px;
		}
	}

	.google-signup-separator {
		position: relative;
		background: rgba(28, 52, 84, 0.26);
		width: 100%;
		height: 1px;
		margin: 26px 0 25px 0;

		&:after {
			--width: 56px;
			position: absolute;
			width: var(--width);
			left: calc(50% - var(--width) / 2);
			top: -9px;
			font-size: 12px;
			color: #1B2B41;
			background: white;
			content: 'OR';
			text-align: center;
		}
	}

}

