.orders {
	font-family: UntitledSans-Regular;
	h4, h6 {
		width: 100%;
		text-align: center;
		padding-top: 27px;
		margin: 0;
	}
	h4 {
		font-family: SabonLTPro-Italic;
		font-size: 28px;
		letter-spacing: 1.3px;
	}
	h6 {
		font-size: 18px;
		letter-spacing: 0.68px;
	}
	.empty {
		padding-top: 35px;
		h6 {
			line-height: 55px;
			letter-spacing: 0.13px;
			padding: 0;
			margin: 0;
			&:last-of-type {
				color: #5a5a61;
			}
		}
		a {
			font-family: UntitledSans-Medium;
			margin: 35px auto;
			max-width: 330px;
			background-color: #F88379;
			color: #fff;
			display: block;
			font-size: 16px;
			border-radius: 4px;
			padding: 15px 0;
			letter-spacing: 1.1px;
			text-transform: uppercase;
			line-height: 20px;
			text-align: center;
			&:hover {
				background-color: #FA948D;
				color: #fff;
			}
		}
	}
}