.gift-card-choose {
	margin: 0 auto;
	padding-top: 30px;

	button {
		width: 100%;
	}
	input[type="text"], input[type="email"] {
		font-family: UntitledSans-Medium;
		font-size: 14px;
		color: #000;
		letter-spacing: 0.76px;
		padding: 18px 0;
		border: none;
		border-radius: 0;
		border-bottom: 1px solid #979797;
		box-shadow: none;
		&::placeholder {
			font-family: UntitledSans-Regular;
			color: #6d7278;
		}
	}
	.title {
		font-family: UntitledSans-Medium;
		font-size: 18px;
		letter-spacing: 0.987px;
	}

	.designs {
		.card-design {
			padding: 0 5px;
			img {
				max-width: 100%;
			}
		}
		.text {
			font-family: UntitledSans-Regular;
			font-size: 16px;
			letter-spacing: 0.87px;
			padding-top: 20px;
		}
	}
	.details {
		max-width: 490px;

		.col, .col-sm-6 {
			padding: 0 5px;
		}

		.amounts {
			margin-bottom: 36px;

			button {
				width: 100%;
				border: 1px solid #979797;
				font-family: UntitledSans-Medium;
				font-size: 18px;
				letter-spacing: 0.98px;
				padding: 15px 20px;
				text-transform: none;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				min-height: 66px;

				&.selected {
					background-color: #f88379;
					border: 1px solid #f88379;
					color: #fff;
				}
				label {
					font-family: UntitledSans-Regular;
					font-style: italic;
					font-size: 16px;
					letter-spacing: 0.52px;
					padding: 0;
					margin-top: 3px;
					margin-bottom: -7px;
				}
			}
			.input-group {
				height: 100%;
				font-family: UntitledSans-Medium;

				input {
					border: 1px solid #979797;
					height: 100%;
					outline: none;
					box-shadow: none;
					color: #000;
					font-size: 18px;
					background: transparent;
					text-align: center;
					&::placeholder {
						color: #000;
					}
				}
				&.selected {
					input {
						background-color: #f88379;
						border: 1px solid #f88379;
						color: #fff;
						&::placeholder {
							color: #fff;
						}
					}
				}
			}
			&.error {
				button, input {
					border-color: #e02020;
				}
			}

			@media screen  and (max-width:780px){
				display: grid;
				grid-template-columns: repeat(3, 1fr);
				gap: 10px;
				.custom{
					width: 115px;
					height: 65px;
				}
			}
		}

		label {
			font-family: UntitledSans-Medium;
			font-size: 18px;
			letter-spacing: 0.98px;
			padding-top: 20px;
		}

		textarea {
			font-family: UntitledSans-Medium;
			font-size: 14px;
			color: #000;
			height: 103px;
			margin-top: 33px;
			border: 1px solid #979797;
			&::placeholder {
				font-family: UntitledSans-Regular;
				color: #6d7278;
			}
		}

		.comment {
			font-family: UntitledSans-Regular;
			font-size: 14px;
			margin: 22px 0 25px;
			letter-spacing: 0.76px;
			color: #6d7278;

			@media screen and (max-width: 756px){
				margin-bottom: 50px;
			}
		}


		.error {
			font-family: UntitledSans-Regular;
			font-size: 12px;
			letter-spacing: 0.35px;
			color: #e02020;
			max-width: 100%;
			&.custom {
				text-align: right;
				position: absolute;
				bottom: -30px;
				right: 5px;
			}
		}
	}


	@media screen and (max-width: 768px) {
		.details {
			margin-bottom: 30px;
			.amounts {
				button {
					padding: 10px;
				}
				.input-group {
					width: auto;
				}
			}
			.input-fields {
				input {
					font-size: 16px;
					padding-top: 35px;
				}
			}
		}
	}
}
