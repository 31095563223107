.goals-header {
	position: relative;

	.profile-image {
		text-align: center;
		img {
			width: 78px;
			height: 78px;
			border-radius: 50%;
		}
	}

	.name {
		font-family: SabonLTPro-Italic;
		font-size: 24px;
		letter-spacing: 0.24px;
		text-align: center;
		padding: 18px 0;
	}
}

.goals-select {
	.answers {
		flex-direction: column;
		padding-top: 35px;
		margin: 0 auto;

		.radio-button {
			.form-check-label {
				font-family: UntitledSans-Regular;
				font-size: 22px;
				letter-spacing: 0.22px;
				text-transform: none;
				line-height: 1;
			}
		}
	}

	@media screen and (max-width: 576px) {
		.answers {
			width: 100%;
			margin: 0 20px;
			.radio-button {
				&.form-check {
					white-space: normal;
				}
				.form-check-label {
					font-size: 18px;
					letter-spacing: 0.18px;
				}
			}
		}
	}
}

.goals-select, .goals-plans, .goals-checkout {
	&.container {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-top: 50px;
		max-width: 1500px;
	}

	.title {
		font-family: UntitledSans-Regular;
		font-size: 24px;
		text-align: center;
		width: 100%;
		padding-bottom: 28px;
	}

	.chat-row {
		font-family: UntitledSans-Regular;
		padding: 15px 40px;
		border: 2px solid #dbdbdb;
		font-size: 18px;
		line-height: 31px;
		letter-spacing: 0.18px;
		border-radius: 50px;
		max-width: 557px;
		white-space: pre-wrap;

		strong {
			font-family: UntitledSans-Medium;
			font-weight: normal;
			text-transform: capitalize;
		}
	}
	@media screen and (max-width: 576px) {
		&.container {
			display: flex;
			flex-direction: column;
			padding-top: 0px;
			align-items: normal;
		}
		.chat-row {
			font-size: 16px;
			letter-spacing: 0.16px;
			padding: 10px 0;
			margin: 17px 20px;
			border-radius: 30px;
		}
		.plans {
			&.container {
				padding: 0 20px;
			}
		}
	}
}

.goals-select {
	@media screen and (max-width: 576px) {
		&.container {
			display: flex;
			padding: 0 20px;
		}
	}
}

.goals-checkout {
	height: 100%;
	/*&.container {
		&.side-collapse-open {
			height: 100%;
		}
	}
	@media screen and (max-width: 576px) {
		&.container {
			height: calc(100vh + 30px);
		}
	}*/
}