.tip {
	width: 100%;
	overflow: hidden;
	max-width: 490px;
	margin: 0 auto;

	.loader {
		width: 100%;
		height: 100%;
		background-color: rgba(255, 255, 255, .7);
		position: absolute;
		top: 0;
		left: 0;
		z-index: 999;
		pointer-events: none;
	}

	.modal-header {
		.profile-photo {
			border-radius: 50%;
			width: 80px;
			height: 80px;
			margin-bottom: 13px;
		}
	}
	.modal-body {
		font-family: UntitledSans-Regular;
		max-width: 100%;
		text-align: center;
		font-size: 18px;
		letter-spacing: 0.59px;

		.title {
			font-size: 22px;
			color: #000;
		}

		.text {
			font-size: 20px;
			margin-bottom: 35px;
			color: #000;
		}

		.btn-group {
			width: 100%;
			label {
				width: 25%;
				height: 94px;
				border: 1px solid #dbdbdb;
				border-radius: 0;
				font-family: UntitledSans-Medium;
				background-color: transparent;
				color: #000;
				font-size: 20px;
				letter-spacing: 0.98px;
				&.active {
					background-color: #000;
					border: 1px solid #000;
					color: #fff;
				}
			}
		}

		.custom-btn {
			font-family: UntitledSans-Medium;
			font-size: 16px;
			text-align: right;
			padding-top: 8px;
			cursor: pointer;
			&:after {
				position: relative;
				content: '';
				display: inline-block;
				width: 7px;
				height: 7px;
				border-right: 2px solid black;
				border-top: 2px solid black;
				transform: rotate(45deg);
			}
		}

		.custom-input {
			.input-group-text, .form-control {
				font-size: 22px;
				border: none;
				background-color: transparent;
			}
			.form-control {
				border-bottom: 1px solid #000;
				&:focus {
					box-shadow: none;
				}
				&::placeholder {
					color: #dbdbdb;
				}
			}
		}

		.error {
			margin: 33px 0 108px;
			text-align: left;
			font-family: UntitledSans-Regular;
			color: #e02020;
			font-size: 14px;
			letter-spacing: 0.45px;
			height: 40px;
			a {
				color: #e02020;
				font-family: UntitledSans-Medium;
			}
		}
	}
	.modal-footer {
		button {
			height: 60px;
			font-size: 16px;
		}
		p {
			font-family: UntitledSans-Regular;
			font-size: 16px;
			letter-spacing: 0.52px;
		}
	}
}