.valentine {
	z-index: 1060;
	&.mobile-collapse-open {
		position: fixed;
	}

	&.container-fluid {
		padding: 57px 0 45px 36px;
	}

	.loader {
		position: fixed;
	}

	.title {
		h1 {
			font-family: SabonLTPro-Italic;
			font-size: 40px;
			letter-spacing: 2.17px;
			text-align: center;
		}
		h3 {
			font-family: UntitledSans-Regular;
			font-size: 16px;
			line-height: 29px;
			max-width: 708px;
			margin: 0 auto;
			text-align: center;
			padding: 13px 0 20px;
		}
	}

	.mobile-side-collapse {
		padding: 55px 25px 55px 45px;
		&.hide {
			.filter-btns {
				left: -100vw;
			}
		}
	}

	.content {
		.filters {
			flex-direction: column;
			max-width: 280px;
			padding-top: 45px;

			.filter {
				label {
					font-family: SabonLTPro-Italic;
					font-size: 22px;
					letter-spacing: 1.2px;
				}
				.filter-option {
					label{
						font-family: UntitledSans-Regular;
						font-size: 14px;
						line-height: 35px;
						&:before {
							border: 1px solid #000;
							width: 20px;
							height: 20px;
							margin-right: 10px;
						}
					}
				}
				.filter-expand {
					font-family: UntitledSans-Medium;
					font-size: 16px;
					letter-spacing: 0.48px;
					text-decoration: underline;
					cursor: pointer;
					padding: 7px 0 45px;
				}
			}
		}

		.results {
			display: flex;
			flex-wrap: wrap;
			padding: 0;
			max-width: 1100px;

			.infinite-scroll-component {
				display: flex;
				flex-wrap: wrap;
			}

			.filters-bar {
				max-height: 32px;

				&.mobile {
					display: flex;
					margin: 26px auto 12px;
					flex-wrap: nowrap;
					overflow-y: scroll;
					max-height: none;
					height: 40px;
					.badge {
						font-family: UntitledSans-Regular;
						font-size: 14px;
						height: 26px;
						padding: 5px 10px;
						border: 1px solid #979797;
						margin-right: 10px;
						border-radius: 4px;
						&.selected {
							background: #000;
							color: #fff;
							border-color: #000;
						}
					}
				}
				.filter-icon {
					width: 23px;
					height: 15px;
					cursor: pointer;
				}
			}

			.filter-badges {
				padding-top: 10px;
				.badge {
					font-family: UntitledSans-Regular;
					font-size: 16px;
					position: relative;
					margin: 5px 10px 5px 0;
					padding: 5px 20px 5px 8px;

					.close {
						top: 10px;
						right: 10px;
						left: auto;
						width: 7px;
						height: 7px;
						&:before, &:after {
							height: 10px;
							left: auto;
							right: 0;
							width: 1px;
						}
					}
				}
			}

			.campaign-item {
				width: 333px;
				height: 545px;
				margin: 15px 15px 40px 15px;
				position: relative;
				display: inline-flex;
				flex-direction: column;
				align-items: flex-start;
				justify-content: flex-start;
				cursor: pointer;
				background-color: #fff;
				color: #000;

				&:hover {
					.image-container {
						.image-overlay, .cart-icon, &:before {
							display: flex;
							text-align: center;
						}
					}
				}

				.image-container {
					&:before {
						content: '';
						background-color: #fff;
						border-radius: 50%;
						width: 46px;
						height: 46px;
						position: absolute;
						z-index: 1;
						right: 10px;
						top: 10px;
						display: none;
					}

					.cart-icon {
						position: absolute;
						right: 19px;
						top: 17px;
						z-index: 10;
						cursor: pointer;
						display: none;
						&.add:after {
							left: 10px;
						}
						&.added:after {
							left: 12px;
						}
					}

					.image-overlay {
						background-color: rgba(0,0,0,0.5);
						width: 100%;
						height: 390px;
						position: absolute;
						color: #fff;
						display: none;
						align-items: center;
						justify-content: center;
						font-family: UntitledSans-Medium;
						font-size: 20px;
					}
				}

				img {
					height: 390px;
					width: 100%;
					object-fit: contain;
					padding: 8px;
					border: 1px solid #f4f4f4;
				}

				span {
					width: 100%;
					p {
						font-family: UntitledSans-Regular;
						font-size: 14px;
						margin: 0;
						display: block;
					}
					.brand {
						padding: 18px 0 0;
						text-transform: uppercase;
					}
					.name {
						color: #44474b;
						padding: 20px 0 15px;
					}
					.price {
						font-family: UntitledSans-Medium;
						line-height: 20px;
						padding-top: 5px;
						display: inline-block;
						padding-right: 7px;
						&.line-through {
							font-family: UntitledSans-Regular;
							text-decoration: line-through;
						}
					}
					.picked {
						color: #8e8e93;
						font-size: 12px;
						letter-spacing: 0.6px;
						padding: 10px 0;
					}
					.out-of-stock {
						font-size: 14px;
						color: #f50101;
						letter-spacing: 0.22px;
					}
				}
				.cart-link {
					display: none;
					font-family: UntitledSans-Medium;
					font-size: 14px;
					line-height: 20px;
					width: 100%;
					text-decoration: underline;
				}
			}

			.dropdown-toggle {
				padding: 0;
				margin-right: 34px;
				&:after, &:active:after, &:focus:after {
					width: 0;
					height: 0;
					border-left: 5px solid transparent;
					border-right: 5px solid transparent;
					border-top: 5px solid #000;
					transform: none;
				}

				.dropdown-item {
					font-family: UntitledSans-Regular;
					font-size: 18px;
					line-height: 32px;
					padding-left: 10px;
				}
			}

			.sort {
				.dropdown-toggle {
					font-family: UntitledSans-Regular;
					padding: 0;
					margin-right: 10px;
				}

				text-align: right;
			}
		}
	}

	@media screen and (max-width: 576px) {
		&.container-fluid {
			padding: 0 25px;
		}

		.content {
			.results {
				.infinite-scroll-component {
					justify-content: space-between;
					padding: 0 15px 50px;
				}
				.filter-badges {
					padding: 0 15px;
				}
				.campaign-item {
					width: 50%;
					height: 310px;
					margin: 12px 0 12px;
					align-items: center;
					max-width: calc(50% - 12px);
					img {
						max-height: 200px;
					}
					.image-container {
						.cart-icon {
							display: none;
						}
						.image-overlay {
							display: flex;
							background-color: transparent;
							span {
								display: none;
							}
						}
						&:before {
							display: none;
						}
					}
					span {
						.brand {
							padding-top: 7px;
							max-width: 100%;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
						}
					}
					.cart-link {
						display: block;
					}
					&:hover {
						.image-container {
							.cart-icon, &:before {
								display: none;
							}
						}
					}
				}
				.sort {
					padding-right: 15px;
					.dropdown-toggle {
						font-size: 14px;
						margin-right: 0;
					}
				}
			}
		}
	}
}

.valetine-mobile-filters {
	max-width: 300px;

	&.container-fluid {
		max-width: 100%;
		max-height: 100%;
		overflow-y: scroll;
		overflow-x: hidden;
	}

	.filters-container {
		overflow: scroll;
		flex-direction: column;
	}

	.filters-title {
		font-family: UntitledSans-Regular;
		font-size: 16px;
		line-height: 35px;
	}

	.filters-list {
		.filter-row {
			border: none;
			padding: 15px 0;
			border-bottom: 1px solid #f4f4f4 !important;

			.filter {
				cursor: pointer;
				font-family: UntitledSans-Medium;
				font-size: 18px;
				margin: 0;
				line-height: 35px;
				letter-spacing: 0.89px;
				padding: 0;
				background: transparent;
				border: none
			}

			.expand-sign {
				&:after {
					content: '+';
					font-size: 18px;
					width: 10px;
					position: absolute;
					right: 0;
					top: 13px;
					text-align: center;
				}
			}

			&.active {
				.expand-sign:after {
					content: '-';
				}
			}

			.card-body {
				padding: 0;
				max-height: 350px;
				overflow-y: scroll;
			}
		}
	}

	.filter-item {
		padding: 0;
		.filter-label {
			font-family: UntitledSans-Regular;
			font-size: 16px;
			letter-spacing: 0.89px;
			line-height: 40px;
			padding: 0;

			&:before {
				width: 20px;
				height: 20px;
				position: absolute;
				right: 0;
				margin: 0;
				border: 1px solid #000;
			}
			&:after {
				left: auto;
				top: 14px;
				right: 7px;
			}
		}

	}

	.show-all {
		padding: 0;
		margin-top: 10px;
		text-decoration: underline;
		cursor: pointer;
	}

	.filter-btns {
		position: fixed;
		left: 0;
		bottom: 0;
		width: 100vw;
		padding: 10px 37px;
		display: flex;
		align-items: center;
		margin: 0;
		background: #fff;
		.clear {
			font-family: UntitledSans-Medium;
			font-size: 18px;
			text-decoration: underline;
			cursor: pointer;
		}
		.btn {
			font-size: 16px;
			padding: 12px;
			width: 100%;
			text-transform: none;
		}
	}
}