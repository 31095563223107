.onboarding .body-type {
	.content {
		display: flex;
		flex-direction: column;
		align-items: center;

		.question {
			&.web {
				max-width: 1000px;

				@media screen and (max-width: 576px) {
					display: none;
				}
			}
			&.mobile {
				display: none;

				@media screen and (max-width: 576px) {
					display: block;
				}
			}
		}
	}

	.types {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		gap: 14px;
		margin: 20px 0;

		@media screen and (max-width: 576px) {
			max-width: 500px;
			margin: 0;
		}

		.tag {
			padding: 14px;
			line-height: 1;
			border: 1px solid black;
			font-size: 16px;
			text-transform: uppercase;
			background: white;
			border-radius: 8px;
			cursor: pointer;

			&[data-selected="true"] {
				background: black;
				color: white;
				font-weight: bold;
			}
		}
	}


}