.outfit {
	display: flex;
	flex-wrap: wrap;
	/*align-items: stretch;*/

	.look {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		border: 1px solid #dbdbdb;
		background-color: #fff;
		padding-top: 20px;
		p {
			font-family: SabonLTPro-Italic;

			&:first-letter {
				text-transform: capitalize;
			}
		}
		.outfit-image {
			width: 100%;
			margin-top: 40px;
		}

	}
	.items {
		height: 739px;
		overflow-y: scroll;
		.container {
			padding: 0 0 0 15px;
		}

		&.no-scroll {
			overflow: hidden;
		}

		&::-webkit-scrollbar {
			-webkit-appearance: none;
			width: 5px;
		}

		&::-webkit-scrollbar-thumb {
			border-radius: 5px;
			background-color: #f4f4f4;
			-webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
		}

		.col-6, .col-4 {
			padding: 0 13px 19px;
			img {
				max-height: 235px;
				max-width: 180px;
				object-fit: contain;
				height: 100%;
			}
			&.catalog,
			&.shop {
				.cart-icon {
					position: absolute;
					top: 4px;
					right: 25px;
					min-width: 20px;
					margin: 0;
					z-index: 999;
					cursor: pointer;
				}
				p, a {
					display: block;
				}
			}
		}
		.item {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-evenly;
			height: 360px;
			text-align: center;
			padding: 20px 10px 10px;
			border: 1px solid #dbdbdb;
			background-color: #fff;
			cursor: pointer;
			img {
				width: 100%;
			}
			span {
				width: 100%;
				text-align: center;
			}
			p, a {
				font-family: UntitledSans-Regular;
				font-size: 14px;
				margin: 0;
				&.link {
					color: #000;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
				&.bold {
					font-weight: bold;
				}
				&.brand {
					text-transform: uppercase;
					max-height: 42px;
				}
			}

			.shop-externally {
				font-size: 14px;
				font-weight: 700;
				text-decoration: underline;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
			}
		}
	}

	@media screen and (max-width: 576px) {
		.look {
			margin: 0;
			border: none;
			padding-bottom: 15px;
			p {
				font-size: 18px;
			}
			.outfit-image {
				border: none;
			}
		}

		.items {
			padding: 0;
			margin: 0;
			overflow: visible;
			height: auto;
			.col-6 {
				padding: 0;
				.item {
					padding: 26px 15px 33px;
					border: 1px solid #f4f4f4;
				}
				img {
					padding: 10px 0;
				}
				p.price, a.link {
					bottom: 0;
					position: relative;
				}
				&.shop .cart-icon {
					right: 10px;
				}
				&.catalog .cart-icon {
					right: 10px;
				}
			}
			p.shop-board {
					position: relative;
					&:after {
						top: 100%;
						left: 50%;
						border: solid transparent;
						content: '';
						height: 0;
						width: 0;
						position: absolute;
						pointer-events: none;
						border-top-color: #f4f4f4;
						border-width: 10px;
						margin-left: -13px;
						z-index: 99;
					}
				}
		}
	}
}