.feed {
	padding-top: 36px;
	background-color: #F2F2F2;
	min-height: 100%;

	@media screen and (max-width: 992px) {
		padding-top: 12px;
	}

	.outfits {
		max-width: 790px;
		margin: 0 auto;

		@media screen and (max-width: 992px) {
			max-width: 520px;
			padding: 0 12px;
		}

		.scrolled-content {
			display: flex;
			flex-direction: column;
			max-width: 520px;
			overflow: hidden;
			gap: 16px;

			@media screen and (max-width: 992px) {
				gap: 12px;
			}
		}

		.dropdown {
			display: block;
			order: 1;

			@media screen and (max-width: 992px) {
				margin-top: 12px;
			}

			.dropdown-toggle {
				text-transform: capitalize;
				text-align: left;
				padding: 0;
				font-size: 16px;
				&:active, &:focus {
					font-size: 16px;
					&:after {
						width: 8px;
						height: 8px;
					}
				}
				&:after {
					width: 8px;
					height: 8px;
				}
			}
			.dropdown-menu {
				width: 182px;
				background-color: #000;
				border-radius: 0;
				font-size: 14px;
				line-height: 25px;
				letter-spacing: 1.07px;
				.dropdown-item {
					font-size: 18px;
					letter-spacing: 1.38px;
					&:hover {
						background-color: #000;
					}
				}

				.dropdown-item, p {
					color: #fff;
					margin: 0;
					padding: 0 10px;
				}
				p {
					color: #dbdbdb;
				}
			}
		}
	}

	.feedLoading{
		background-color: #F2F2F2;
		height: 100vh;
		z-index: 1;
		position: fixed;
		left: 0;
		right: 0;
	}

	.feed-item {
		padding: 20px 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		background: #fff;
		order: 4;

		&:nth-child(3) {
			order: 3;
		}

		.outfit-image-container {
			position: relative;
			display: flex;
			flex-direction: column;
			align-items: center;
		}

		&.type-moodboard {
			.outfit-hover {
				display: none;
			}
			.outfit-image {
				cursor: default;
			}
		}

		.profile-image {
			width: 53px;
			height: 53px;
			border-radius: 50%;
		}

		.outfit-image {
			max-width: 403px;
			border: 1px solid #f4f4f4;
			cursor: pointer;
		}

		.outfit-hover {
			width: 100%;
			height: 100%;
			max-width: 403px;
			position: absolute;
			top: 0;
			left: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;

			.favorite-button {
				top: 5px;
				right: 5px;
				opacity: 0;
			}
			.hover-button {
				display: flex;
				opacity: 0;
				transition: opacity 300ms ease-in-out;
				&:before {
					content: '';
					width: 100%;
					height: 100%;
					position: absolute;
					top: 0;
					left: 0;
					background-color: #000;
					opacity: .5;
					z-index: 1;
				}
				&:after {
					content: 'Shop the look';
					font-weight: 500;
					color: #fff;
					font-size: 20px;
					letter-spacing: 1.11px;
					z-index: 2;
				}
			}
			&:hover {
				.favorite-button{
					opacity: 1;
					&.off {
						color: #fff;
					}
				}
				.hover-button {
					opacity: 1;
				}
			}
		}

		.title {
			font-size: 16px;
			line-height: 23px;
			letter-spacing: 0;
			margin: 15px 0 10px;
		}

		.name {
			font-family: Sabon, sans-serif;
			font-size: 16px;
			letter-spacing: 0.11px;
			margin: 10px 0 7px;
			font-style: italic;
		}

		a {
			width: 309px;
			height: 49px;
			line-height: 1;
			margin-top: 24px;
		}

		@media screen and (max-width: 576px) {
			border: none;

			.col {
				text-align: center;
				display: flex;
				justify-content: center;
				padding: 0;
			}
			.outfit-image {
				width: 88%;
				height: 388px;
				object-fit: contain;
			}
			.outfit-hover {
				max-width: 88%;
				.favorite-button {
					opacity: 1;
					right: 0;
				}
				&:hover {
					.hover-button {
						opacity: 0;
					}
					.favorite-button.off {
						color: #000;
					}
				}
			}
		}
	}

	.matches {
		position: relative;
		margin-bottom: -16px;
		order: 2;

		@media screen and (max-width: 992px) {
			margin-bottom: 0;
		}

		&.has-matches {
			@media screen and (max-width: 992px) {
				order: 4;
				background: #fff;
				padding: 0 15px 10px;

				.title {
					background: #fff;
					padding: 13px 15px 8px;
					font-size: 18px;
				}
				.matches-container {
					position: relative;
					overflow-x: scroll;
					white-space: nowrap;
				}
				.take-btn {
					background-color: #fff;
					color: #000;
					border: 1px solid #dbdbdb;
					margin: 0 0 20px 0;
				}
			}
		}

		&.no-matches {
			@media screen and (max-width: 992px) {
				order: 0;
			}
		}

		.positioner {
			position: absolute;
			right: -16px;
			top: -40px;

			@media screen and (max-width: 992px) {
				position: static;
			}
		}

		.content {
			position: fixed;
			min-width: 250px;

			@media screen and (max-width: 992px) {
				position: static;
			}
		}

		.title {
			font-size: 16px;
			line-height: 35px;
			letter-spacing: 0.89px;
			padding: 0;
			margin-bottom: 5px;
			font-weight: 500;
		}

		.feed-matches-carousel {
			max-width: 250px;

			@media screen and (max-width: 992px) {
				max-width: none;
			}

			.slick-dots  {
				bottom: -30px;

				li {
					width: 13px;
					button:before, &.slick-active button:before {
						font-size: 12px;
						color: transparent;
						border: 1px solid #dbdbdb;
						border-radius: 50%;
						width: 11px;
						height: 11px;
						opacity: 1;
					}
					&.slick-active {
						button:before {
							background: #f88379;
						}
					}
				}
			}

			.slick-arrow {

				&.slick-prev, &.slick-next {
					z-index: 99;
					&:before {
						content: '';
						display: inline-block;
						width: 10px;
						height: 10px;
						border-right: 2px solid black;
						border-top: 2px solid black;
					}
				}
				&.slick-prev {
					left: 14px;
					&:before {
						transform: rotate(225deg);
					}
				}
				&.slick-next {
					right: 14px;
					&:before {
						transform: rotate(45deg);
					}
				}
			}
		}
	}

	.take-btn {
		position: absolute;
		display: flex;
		flex-direction: column;
		top: 40px;
		gap: 16px;
		padding: 50px;
		text-align: center;
		white-space: nowrap;
		color: white;
		text-decoration: none;
		line-height: 1;
		background: black url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAxNyAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE2LjM1NCA3LjU1MTZDOS4zNDc2NiA2Ljk1NDcyIDguODI0NjUgNi40NzUwNCA4LjE3OTU4IDBDNy41MzI2NiA2LjQ3NSA3LjAwOTc2IDYuOTU0NiAwIDcuNTUxNkM3LjAwOTc4IDguMTQ1MzYgNy41MzI1NyA4LjYzMjA2IDguMTc5NTggMTUuMTA0QzguODI0ODIgOC42MzIyIDkuMzQ3NzIgOC4xNDU0IDE2LjM1NCA3LjU1MTZaIiBmaWxsPSIjRkNGQ0ZFIi8+CjxwYXRoIGQ9Ik01LjI4OTM2IDE0LjEwNzFDNS4wNDIxNiAxNi41NzI3IDQuODQyNzIgMTYuNzU2MyAyLjE3MzgzIDE2Ljk4NDVDNC44NDI3MiAxNy4yMDg3IDUuMDQyMjMgMTcuMzk3NyA1LjI4OTM2IDE5Ljg2NDlDNS41MzE1MyAxNy4zOTY5IDUuNzMxODEgMTcuMjA4NyA4LjQwNDI0IDE2Ljk4NDVDNS43MzE5MiAxNi43NTcxIDUuNTMxNTUgMTYuNTcyNyA1LjI4OTM2IDE0LjEwNzFaIiBmaWxsPSIjRkNGQ0ZFIi8+CjxwYXRoIGQ9Ik0xNC45NzM4IDEzLjE0NDdDMTUuMTM0NyAxMS41NDIzIDE1LjI2NDYgMTEuNDI0MyAxNyAxMS4yNzM2QzE1LjI2NTQgMTEuMTI2NyAxNS4xMzQ3IDExLjAwODcgMTQuOTczOCA5LjQwNjM3QzE0LjgxNDYgMTEuMDA4NyAxNC42ODM4IDExLjEyNjcgMTIuOTQ4NCAxMS4yNzM2QzE0LjY4MzggMTEuNDI1MSAxNC44MTQ1IDExLjU0MzEgMTQuOTczOCAxMy4xNDQ3WiIgZmlsbD0iI0ZDRkNGRSIvPgo8L3N2Zz4=) center 11px no-repeat;

		&:hover {
			color: white;
		}

		@media screen and (max-width: 992px) {
			position: static;
			white-space: normal;
			padding: 50px 25px 20px 25px;
		}

		span {
			font-family: Sabon, sans-serif;
			font-style: italic;
			font-weight: 400;
			font-size: 22px;
		}

		u {
			font-size: 18px;
		}
	}

	.feed-match {
		display: inline-block;
		flex-direction: column;
		text-align: center;
		padding: 20px;
		background-color: #fff;

		a {
			font-size: 16px;
			line-height: 20px;
			letter-spacing: 0.6px;
			font-weight: 500;
			color: #000;
			&:hover {
				border-bottom: 2px solid #000;
				color: #000;
			}
		}

		img {
			width: 87px;
			height: 87px;
			border-radius: 50%;
			margin: 0 auto;
		}

		p {
			padding: 0;
			margin: 0;

			&.name {
				font-family: Sabon, sans-serif;
				font-size: 20px;
				margin-top: 12px;
				height: 34px;
				font-style: italic;
			}

			&.location {
				font-size: 14px;
				letter-spacing: 0.65px;
				text-transform: uppercase;
				height: 30px;
				font-weight: 500;
			}
			&.percentage {
				height: 32px;
			}
		}

		@media screen and (max-width: 992px) {
			border: 1px solid #dbdbdb;
			&.container-fluid {
				width: 185px;
				margin: 0 10px 25px;
			}
		}
	}
}



.outfit-page {
	margin: 0 auto;
	max-width: 1140px;
	padding: 0 16px 16px 16px;

	@media screen and (max-width: 767px) {
		padding: 68px 12px 12px 12px;
	}

	.header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		height: 55px;
		padding: 0 12px;
		background: #fff;

		z-index: 1000;

		@media screen and (max-width: 767px) {
			position: fixed;
			left: 0;
			top: 0;
			border-bottom: 1px solid #e7e7e7;
		}

		.back-btn {
			position: relative;
			top: auto;

			label {
				margin-left: 10px;
				margin-bottom: 0;
				font-weight: 500;
			}

			&:after {
				display: none;
			}
		}
		.cart {
			display: none;
			color: #000;

			@media screen and (max-width: 767px) {
				display: block;
			}

			.cart-icon {
				min-width: 26px;
				min-height: 26px;
			}
		}
	}

	.take-btn {
		margin-bottom: 12px;
	}

	p {
		font-size: 18px;
		letter-spacing: 0.14px;
	}

	.infinite-scroll-component {
		.outfit {
			margin-bottom: 60px;
		}
	}

	.more {
		 font-weight: 500;
		 font-size: 20px;
		 padding: 80px 0 13px;
	 }

	@media screen and (max-width: 767px) {
		background-color: #f4f4f4;
		margin: 0;
		overflow: hidden;

		p {
			text-align: center;
			margin: 3px;
			font-size: 14px;
			width: 100%;
		}

		.more {
			font-size: 18px;
			text-align: center;
			padding: 51px 32px 13px;

		}
	}
}