.gift-cards-confirmation {
	margin-top: 98px;
	max-width: 978px;

	.row {
		justify-content: space-between;
	}
	.text {
		max-width: 400px;
		padding: 0;
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;

		h1 {
			font-family: UntitledSans-Medium;
			font-size: 32px;
			letter-spacing: 1px;
			margin-bottom: 18px;
		}
		h4 {
			font-family: UntitledSans-Regular;
			font-size: 18px;
			line-height: 25px;
			letter-spacing: 0.98px;
			margin-bottom: 29px;
			max-width: 348px;
		}
		.another {
			background-color: #F88379;
			color: #fff;
			text-align: center;
			font-family: UntitledSans-Medium;
			font-size: 16px;
			border-radius: 4px;
			padding: 15px 0;
			letter-spacing: 1.1px;
			text-transform: uppercase;
			line-height: 20px;
			width: 100%;
			max-width: 373px;
			margin-bottom: 23px
		}
		.homepage {
			font-family: UntitledSans-Medium;
			font-size: 14px;
			letter-spacing: 1.17px;
			display: flex;
			text-transform: uppercase;
			padding: 0;
			border: 0;
			max-width: 373px;
			align-items: center;
			justify-content: center;
			color: #000;
			&:hover {
				background-color: #fff;
				border: none;
			}
		}
	}
	.card-design {
		max-width: 512px;
		padding: 0;
		p {
			font-family: UntitledSans-Medium;
			font-size: 36px;
			letter-spacing: 2.76px;
			position: absolute;
			right: 27px;
			top: 34px;
		}
		img {
			width: 100%;
		}
	}

	@media screen and (max-width: 768px) {
		margin-top: 29px;
		.text, .card-design {
			padding: 0 15px;
			margin: 0 auto 38px;
		}
		.text {
			h1 {
				font-size: 28px;
			}
			h4 {
				font-size: 14px;
			}
		}
	}
}