.filters {
	max-width: 300px;

	.filters-container {
		overflow: scroll;
		flex-direction: column;
		overflow-y: auto;
		overflow-x: auto;
	}

	.filters-list {
		.filter-row {
			border: none;
			padding: 10px 0;
			border-bottom: 1px solid #f4f4f4 !important;
			background-color: transparent;

			&.disabled {
				pointer-events: none;
				opacity: .5;
			}

			.container-fluid {
				padding: 0;
			}

			.filter {
				cursor: pointer;
				font-family: UntitledSans-Medium;
				font-size: 16px;
				margin: 0;
				line-height: 35px;
				letter-spacing: 0.89px;
				padding: 0;
				background: transparent;
				border: none;

				.selection {
					font-size: 12px;
					line-height: 28px;
					color: #8e8e93;
					letter-spacing: 0.7px;
				}
			}

			.expand-sign {
				&:after {
					content: '+';
					font-size: 18px;
					width: 10px;
					position: absolute;
					right: 0;
					top: 13px;
					text-align: center;
				}
			}

			&.active {
				.expand-sign:after {
					content: '-';
				}
				.filter {
					.selection {
						display: none;
					}
				}
			}

			.card-body {
				padding: 0;
				max-height: 300px;
				overflow-y: scroll;
				overflow-y: auto;
				overflow-x: hidden;
			}

			&.prices {
				.card-body {
					overflow: hidden;
				}
			}
		}
	}

	.filter-item {
		padding: 0;
		img {
			width: 15px;
			height: 15px;
			border: 1px solid #dbdbdb;
			border-radius: 8px;
			margin-right: 5px;
		}
		input[type="checkbox"]:checked + label {
			&.single {
				&:before {
					background: #fff;
				}
				&:after {
					display: block;
					width: 7px;
					height: 7px;
					background: #000;
					border: 0;
					border-radius: 4px;
					top: 14px;
					right: 0;
					left: 2px;
				}
			}
		}
		.filter-label {
			font-family: UntitledSans-Regular;
			font-size: 16px;
			letter-spacing: 0.89px;
			line-height: 35px;
			padding: 0;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			text-transform: capitalize;

			&.single {
				&:before {
					border-radius: 50%;
				}
			}

			&:before {
				height: 20px;
				min-width: 20px;
				max-width: 20px;
				position: absolute;
				right: 0;
				margin: 0;
			}
			&:after {
				left: auto;
				top: 12px;
				right: 7px;
			}
		}
	}

	.filter-slider {
		padding: 0;
		max-width: 135px;
		form {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			max-width: 135px;
			margin-bottom: 30px;
			input {
				&:focus {
					outline: none;
					box-shadow: none;
				}
			}
			.form-control {
				width: 65px;
				height: 23px;
				font-size: 12px;
				padding: 5px;
				margin-right: 5px;
				border: 1px solid #000;
				border-radius: 0;
			}
			button {
				border-radius: 2px;
				min-width: 23px;
				height: 23px;
				padding: 0;
				background-color: #000;
				position: relative;
				&.clear {
					background-color: #fff;
					border: 1px solid #000;
					&:before, &:after {
						position: absolute;
						content: '';
						height: 7px;
						width: 1px;
						background-color: #000;
						border: none;
					  }
					  &:before {
						  top: 8px;
						  left: 10px;
						  transform: rotate(45deg);
					  }
					  &:after {
						  top: 8px;
						  left: 10px;
						  transform: rotate(-45deg);
					  }
				}
				&:after {
					content: "";
					position: absolute;
					top: 5px;
					left: 10px;
					color: #fff;
					transform: rotate(45deg);
					height: 10px;
					width: 5px;
					border-bottom: 1px solid #fff;
					border-right: 1px solid #fff;
				}
			}
		}
		.slider {
			padding: 0 7px;
			.rc-slider-handle {
				margin-top: -6px;
				transition: all 50ms ease;
				&:active, &:focus, .rc-slider-handle-dragging {
					box-shadow: none;
				}
			}
		}
	}

	.filter-search {
		padding: 0;
		.form-group {
			width: 140px;
			position: relative;
			margin-bottom: 12px;
			.magnifier {
				width: 8px;
				height: 8px;
				position: absolute;
				left: 5px;
				top: 7px;
				&:before {
					top: 5px;
					height: 5px;
				}
			}
			.close {
				left: auto;
				right: 2px;
				top: 8px;
				height: 7px;
				width: 7px;
				&:before, &:after {
					left: 0;
				}
			}
			input {
				width: 100%;
				height: 23px;
				font-family: UntitledSans-Medium;
				font-size: 12px;
				letter-spacing: 0.7px;
				border: 1px solid #000;
				border-radius: 2px;
				padding-left: 20px;
				&:focus, &:active {
					outline: none;
					box-shadow: none;
				}
			}
		}

		.filter-item {
			.filter-label {
				line-height: 25px;
			}
		}
	}

	.show-all {
		margin-top: 10px;
		text-decoration: underline;
		cursor: pointer;
	}

	@media screen and (max-width: 576px) {
		&.container-fluid {
			padding: 0;
			max-width: 100%;
		}

		.filters-list .filter-row {
			padding: 15px 0;
		}

		.filters-list .filter-row .filter,
		.filter-item .filter-label {
			font-size: 18px;
		}
	}
}