.no-padding {
  padding: 0 !important;
}

.nav-bar {
	.navbar-collapse {
		@media screen and (max-width: 576px) {
			padding: 30px;
		}
	}
}

.navbar-light .navbar-nav .nav-link {
  color: #000;
}

.btn-dark,
.btn-light,
.btn-warning,
.btn-primary,
.btn-info,
.btn-outline-dark {
	font-size: 16px;
	border-radius: 4px;
	padding: 15px 5px;
	border: none;
	letter-spacing: 1.1px;
	text-transform: uppercase;
	line-height: 20px;
	font-weight: 500;
	color: #fff;
	&:hover, &:active {
		color: #fff;
		opacity: 0.9;
	}
	&:focus {
		box-shadow: none;
	}
	&:disabled {
		&:hover, &:active {
			opacity: 0.65;
		}
	}
	&:not(:disabled):not(.disabled):active:focus, &.focus {
		box-shadow: none;
	}
	@media screen and (max-width: 768px) {
		&:hover {
			opacity: 1;
		}
	}
}

.btn-dark {
	background-color: #000;
	min-width: 100px;
	&:disabled {
		&:hover {
			background-color: #343a40;
			border-color: #343a40;
			opacity: .65;
		}
	}
	&:focus, &:active {
		background-color: #000;
	}
}

.btn-light {
	background-color: #fff;
	color: #000;
	border: 1px solid #000;
	&:hover, &:active, &:focus {
		border: 1px solid #000;
		color: #000;
		background-color: #fff;
	}
	&:disabled {
		&:hover {
			background-color: #fff;
			border-color: #343a40;
			opacity: .65;
		}
	}
}

.btn-warning {
	background-color: #fa7268;
	color: #fff;

	&:disabled {
		color: #fff;
		background-color: #FCA59F !important;
		border: none;
	}
	&:hover, &:active {
		color: #fff;
		background-color: #FA948D;
	}
	&:focus {
		color: #fff;
		background-color: #fa7268;
	}

	&:not(:disabled){
		&:not(.disabled){
			&:active {
				background-color: #FCA59F;
				color: #fff;
			}
		}
	}

}

.btn-info {
	background-color: #415e9b;
	&:hover, &:active, &:focus, &:disabled {
		background-color: #415e9b !important;
	}
}

.btn-outline-dark {
	color: #000;
	border-color: #000;

	&:hover {
		color: #000;
		border-color: #000;
		background-color: transparent;
	}

	&:not(:disabled):not(.disabled):active {
		color: #000;
		background-color: transparent;
		border-color: #000;
	}
}

.nav-link:hover {
  color: #000;
}

a:hover {
  text-decoration: none;
}

form {
	.form-group {
		padding-bottom: 20px;
		margin: 0;
	}

	&.was-validated {
		.form-control {
			&:invalid, &:valid, &:focus {
				border: 1px solid #979797 !important;
				background-image: none;
				box-shadow: none !important;
			}
		}
		.invalid-feedback {
			font-size: 12px;
			letter-spacing: 0.4px;
			line-height: 20px;
			color: #ff896b;
			bottom: 0;
			position: absolute;
		}
	}
}

input.styled-checkbox[type="checkbox"], .form-check-input[type="checkbox"] {
	display: none;
	margin: 4px 0 0;

	+ label {
		display: flex;
		align-items: center;
		cursor: pointer;
		margin: 0;
		font-size: 12px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: center;
		color: #000;

		&:before {
			content: "";
			font-size: 10px;
			display: inline-block;
			color: #000;
			width: 15px;
			height: 15px;
			margin: 0 7px 0 0;
			cursor: pointer;
			background: #fff;
			border: 2px solid #000;
			border-radius: 20%;
		}
	}
	&:checked + label {
		&:before {
			background: #000;
		}
		&:after {
			content: "";
			position: absolute;
			top: 1px;
			left: 5px;
			color: #fff;
			transform: rotate(45deg);
			height: 10px;
			width: 5px;
			border-bottom: 2px solid #fff;
			border-right: 2px solid #fff;
		}

	}
}

.badge-light {
	border: 1px solid #979797;
	border-radius: 0;
	font-size: 16px;
	letter-spacing: 0.65px;
	padding: 7px 15px;
	background-color: #fff;
	font-weight: normal;
}

.dropdown {
	.dropdown-toggle {
		&.btn-secondary,
		&.btn-secondary:active,
		&.btn-secondary:focus
		{
			font-size: 18px;
			color: #000;
			background-color: transparent;
			border: none;
			box-shadow: none;

			&:after {
				position: relative;
				top: 2px;
				content: '';
				display: inline-block;
				width: 10px;
				height: 10px;
				border-left: 0;
				border-right: 2px solid black;
				border-top: 2px solid black;
				transform: rotate(135deg);
				margin-left: 10px;
			}
		}
	}
}

.card {
	&:focus {
		outline: none;
	}
}

.tooltip {
	top: 5px !important;
	.arrow:before {
		border-bottom-color: #000;
	}
	.tooltip-inner {
		font-size: 14px;
		letter-spacing: 1px;
		background-color: #000;
		padding: 8px 12px;
		box-shadow: rgba(0, 0, 0, 0.5) 0 2px 4px 0;
	}
}
