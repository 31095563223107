.pricing {
	background-color: #f4f4f4;
	margin-top: 70px;
	
	.price{
		max-width: 1500px;
	}

	.title {
		font-family: SabonLTPro-Italic;
		font-size: 32px;
		text-align: center;
	}

	.sub-title {
		font-family: UntitledSans-Regular;
		font-size: 18px;
		text-align: center;
		overflow: hidden;
		transition: height 200ms ease-in-out;

		&.show {
			height: 28px;
		}
		&.hide {
			height: 0;
		}
	}

	.toggle-switch {
		width: 220px;
		margin: 0 auto;
	}

	.plans {
		margin-top: 20px;
		max-width: 1500px;
	}

	.take-care {
		font-family: UntitledSans-Regular;
		margin-top: 20px;
		font-size: 18px;
		line-height: 1.67;
		text-align: center;
		white-space: pre-line;
		padding-bottom: 40px;
	}

	.packages-include {
		border-top: 1px solid #979797;

		.title {
			padding: 70px 0;
		}
		.packages-item {
			text-align: center;
			margin: 0 30px;

			.title {
				font-family: SabonLTStd-Italic;
				font-weight: 700;
				font-size: 20px;
				margin-bottom: 20px;
				padding: 0;
			}
			.description {
				font-family: UntitledSans-Regular;
				font-size: 18px;
				line-height: 1.79;
			}
		}
	}

	.help-center {
		text-align: center;
		font-size: 18px;
		font-weight: 300;

		a {
			font-weight: 500;
			color: #000;
		}
	}

	.ready {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 75px;
		@media screen and (max-width: 576px) {
			.btn {
				display: none;
			}
		}

		.btn {
			margin: 30px 0;
			padding: 15px 40px;
		}
	}

	.consult {
		text-align: center;
		font-size: 16px;
		margin: 90px 0 110px;

		a {
			font-weight: 800;
			color: #000;
		}
	}
}