.settings-info,
.settings-style,
.settings-password,
.settings-payment-method,
.settings-payment-history,
.settings-orders,
.settings-loyalty,
.settings-membership {
	.loader {
		position: fixed;
	}
	label {
		font-family: UntitledSans-Medium;
		font-size: 18px;
		letter-spacing: 0.84px;
		&.section-title {
			margin-bottom: 30px;
		}
	}
	.save-button {
		margin-top: 36px;
		width: 220px;
		height: 54px;
		border-radius: 0;
	}
	.header {
		display: flex;
		flex-direction: column;
		width: 100%;
		.profile-photo {
			width: 83px;
			height: 83px;
			margin: 0 auto;
			&:after {
				width: 25px;
				height: 25px;
			}
		}
		.name {
			font-family: UntitledSans-Medium;
			font-size: 16px;
			color: #000;
			text-align: center;
			padding: 10px 0;
		}
		p {
			font-family: UntitledSans-Regular;
			line-height: 20px;
			color: #8e8e93;
			max-width: 663px;
			font-size: 16px;
			letter-spacing: 0.74px;
			margin-bottom: 36px;
		}
	}

	.breadcrumbs {
		max-width: 800px;
		display: flex;
		align-items: center;
		padding-bottom: 30px;
		a, div {
			font-family: UntitledSans-Regular;
			font-size: 16px;
			letter-spacing: 0.74px;
			padding-right: 20px;
			color: #000;
		}
		.arrow {
			position: relative;
			&:after {
				width: 6px;
				height: 6px;
				border-width: 1px;
			}
		}
	}

	.user-session {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		gap: 25px;
		margin-top: 20px;

		@media screen and (max-width: 1199px) {
			grid-template-columns: 1fr 1fr;
		}

		@media screen and (max-width: 768px) {
			grid-template-columns: 1fr;
		}
	}

	.card {
		height: 275px;
		margin-bottom: 40px;
		border: none;
		border-radius: 4px;
		padding: 13px 24px;
		.inactive {
			position: absolute;
			top: -33px;
			left: 0;
			color: #ff3b30;
			font-size: 16px;
			line-height: 26px;
			letter-spacing: 0.6px;
		}
		.trial {
			position: absolute;
			bottom: -33px;
			left: 0;
			color: #000;
			font-family: UntitledSans-Medium;
			font-size: 16px;
			line-height: 26px;
			letter-spacing: 0.6px;
		}
		.card-header, .card-footer, .card-body {
			display: flex;
			align-items: center;
			background-color: transparent;
			border: none;
			padding: 0;
			.profile-photo {
				margin-right: 10px;
			}
			p {
				margin: 0;
				font-family: UntitledSans-Medium;
				font-size: 16px;
				letter-spacing: 0.6px;
			}
		}
		.card-body {
			.session-info {
				.row {
					.col {
						font-size: 16px;
						line-height: 26px;
						letter-spacing: 0.6px;
						padding: 0;
						&:first-child {
							font-family: UntitledSans-Regular;
							min-width: 167px;
							color: #5a5a61;
						}
						&:last-child {
							font-family: UntitledSans-Medium;
							text-transform: capitalize;
						}
					}
				}

				.edit-payment {
					margin-left: 10px;
					text-decoration: underline;
					cursor: pointer;
					font-family: UntitledSans-Regular;
				}
			}
		}



		.card-footer {
			display: flex;
			justify-content: center;
			button {
				width: 100%;
				max-width: 250px;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
	}

	form {
		.form-control {
			border: none;
			border-radius: 0;
		}
	}

	@media screen and (max-width: 576px) {
		&.container-fluid {
			width: 88%;
			max-width: 100%;		}
		.header {
			.profile-photo, .name {
				display: none;
			}
		}
		.save-button {
			width: 100%;
		}
	}
}

.settings-main {
	h3 {
		font-family: SabonLTPro-Italic;
		font-size: 40px;
		letter-spacing: 0.32px;
		text-align: center;
		margin-bottom: 1.5rem;
	}
	.card {
		padding: 25px;
		border: none;
		border-radius: 4px;
		height: 150px;
		margin-bottom: 20px;
		overflow-x: scroll;
		cursor: pointer;
		.card-title {
			display: flex;
			flex-direction: row;
			align-items: center;
			margin-bottom: 12px;
			div {
				font-family: UntitledSans-Medium;
				font-size: 18px;
				color: #fa7268;
				position: relative;
			}
			.arrow {
				margin-left: 5px;
				&:after {
					width: 6px;
					height: 6px;
					border-width: 1px;
				}
			}
		}
		.card-body {
			font-family: UntitledSans-Regular;
			font-size: 16px;
			letter-spacing: 0.74px;
			color: #8e8e93;
			padding: 0;
		}
	}
	.deactivate {
		font-family: UntitledSans-Regular;
		font-size: 14px;
		letter-spacing: 0.84px;
		position: relative;
		width: 195px;
		cursor: pointer;
		&:hover {
			font-family: UntitledSans-Medium;
		}
	}
}

.settings-info {
	&.container-fluid {
		max-width: 800px;
		padding: 28px 0;
	}
	.header {
		display: flex;
		flex-direction: row;
		align-items: center;
		.profile-photo {
			margin: 0 18px 0 0;
			cursor: pointer;
			&:hover {
				+.photo-btn {
					display: flex;
				}
			}
		}
		p {
			margin-bottom: 0;
		}
		.photo-btn {
			&:hover {
				p {
					display: flex;
				}
			}
			p {
				position: absolute;
				bottom: -15px;
				left: 5px;
				display: none;
				align-items: center;
				justify-content: center;
				width: 106px;
				height: 23px;
				background-color: #000;
				color: #fff;
				font-family: UntitledSans-Medium;
				font-size: 12px;
				letter-spacing: 0.56px;
				margin: 0;
				cursor: pointer;
			}
			input {
				color: transparent;
				width: 0;
				height: 0;
				opacity: 0;
				position: absolute;
				left: 0;
				top: 0;
			}
		}
	}
	form {
		padding-top: 20px;
		.form-group {
			padding-bottom: 10px;
			.field-error-message {
				display: flex;
				margin: 0;
				background-color: #EEC2BE;
				font-size: 12px;
				letter-spacing: 0.56px;
				height: 35px;
				max-width: 365px;
				border-radius: 4px;
				align-items: center;
				justify-content: center;
			}
			.error {
				display: none;
			}
		}
		.form-label {
			color: #4a4a4a;
			font-family: UntitledSans-Medium;
			text-transform: uppercase;
			font-size: 12px;
			letter-spacing: 1.13px;
			width: 100%;
		}
		.input {
			margin: 0;
			.allow-dropdown {
				background-color: #fff;
				border: none;
				height: 47px;
				width: 365px;
	
				.flag-container, input[type=tel] {
					height: 100%;
					border: none;
					&:hover .selected-flag {
						background-color: transparent;
					}
				}
				input[type=tel] {
					font-family: UntitledSans-Regular;
					border-left: 5px solid #F4F4F4;
				}
			}
			&.field-error {
				.form-group {
					padding-bottom: 0;
					input {
						border: 1px solid #ff3b30;
					}
				}
			}
		}
		.form-control {
			width: 365px;
		}
		.gender {
			.input {
				width: auto;
				display: inline-flex;
				padding-right: 15px;
				input[type=checkbox] {
					+ label {
						&:before {
							width: 20px;
							height: 20px;
						}
					}
					&:checked {
						+ label {
							&:before {
								border: none;
							}
							&:after {
								top: 8px;
								left: 5px;
								background-color: #fa7268;
								width: 20px;
								height: 20px;
								border-radius: 10px;
							}
						}
					}
				}
			}
		}
		.arrow {
			&:after {
				width: 5px;
				height: 5px;
				margin-right: 3px;
				border-width: 1px;
			}
		}
		.error {
			margin: 0;
			max-width: 220px;
			color: #ff3b30;
			font-size: 14px;
		}
	}
}

.settings-password {
	&.container-fluid {
		max-width: 800px;
		padding: 28px 0;
	}
	form {
		padding-top: 10px;
		max-width: 365px;
		.form-group {
			padding-bottom: 10px;
		}
		.form-label {
			color: #4a4a4a;
			font-family: UntitledSans-Medium;
			text-transform: uppercase;
			font-size: 12px;
			letter-spacing: 1.13px;
			width: 100%;
		}
		.form-control {
			border: 1px solid #979797;
			border-radius: 0;
			width: 365px;
		}
		.password-error {
			margin: 0;
			background-color: #EEC2BE;
			font-size: 12px;
			letter-spacing: 0.56px;
			height: 35px;
			border-radius: 4px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		.input.field-error {
			input {
				border-color: #ff3b30;
			}
			.form-group {
				padding-bottom: 0;
			}
		}
	}
}


.settings-payment-history {
	.payment-item {
		width: 400px;
		margin-bottom: 55px;
		cursor: pointer;
		.container {
			width: 100%;

			.arrow.triangle {
				left: 33px;
				right: auto;
				top: 55px;
				height: 20px;
				&:after {
					border-left: 5px solid #000;
					border-right: 5px solid transparent;
					border-bottom: 5px solid transparent;
					border-top: 5px solid transparent;
				}
				&.down {
					&:after {
						transform: rotate(90deg);
					}
				}
				&.up {
					&:after {
						transform: rotate(270deg);
					}
				}
			}

			.payment-info {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				label {
					font-size: 16px;
					letter-spacing: 0.6px;
					margin: 0;
				}
				.plan-info {
					text-transform: capitalize;
					color: #5a5a61;
				}
			}
		}

		.collapse, .collapsing {
			position: relative;
			top: 20px;
			.payment-content {
				padding-left: 65px;
				.col {
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					label, p {
						text-transform: capitalize;
						font-size: 16px;
						line-height: 25px;
						letter-spacing: 0.41px;
						margin: 0;
					}
					&.prices {
						p {
							color :#6d7278;
						}
					}
				}
				.items {
					padding-top: 28px;
					width: 315px;
					align-items: center;
					.col {
						display: flex;
						align-items: center;
						justify-content: center;
						font-size: 16px;
						letter-spacing: 0.6px;
						font-family: UntitledSans-Regular;
						img {
							width: 26px;
							height: 26px;
							object-fit: contain;
							margin-bottom: 5px;
						}
					}
					label {
						display: flex;
						align-items: center;
						font-size: 16px;
						letter-spacing: 0.13px;
						margin-left: 15px;
						cursor: pointer;
						.arrow {
							position: relative;
							padding-left: 5px;
							&:after {
								height: 8px;
								width: 8px;
							}
						}
					}
				}
			}
		}
	}
}

.settings-orders {
	.order-item {
		background-color: #fff;
	}
}

.settings-loyalty {
	&.container-fluid {
		max-width: 800px;
	}
	.header {
		flex-direction: row;
		margin-bottom: 45px;
		padding: 0;
		.profile-photo {
			display: inline-flex;
			width: 83px;
			height: 83px;
			margin: 0 22px 0 0;
			&:after {
				width: 45px;
				height: 45px;
				bottom: -10px;
				right: -10px;
			}
		}
		div {
			display: flex;
			flex-direction: column;
			justify-content: center;

			label:last-of-type {
				font-size: 14px;
				text-transform: uppercase;
			}
		}
	}
	.rewards {
		&.general {
			label {
				text-transform: capitalize;
			}
		}
		.list-group {
			margin-bottom: 45px;
			.list-group-item {
				font-family: UntitledSans-Regular;
				background-color: transparent;
				border: none;
				padding: 7px 0;
				&:before {
					content: '';
					width: 10px;
					height: 10px;
					border-radius: 50%;
					background-color: #fa7268;
					display: inline-flex;
					margin-right: 11px;
				}
			}
		}
	}
	
	a {
		font-family: UntitledSans-Medium;
		font-size: 18px;
		letter-spacing: 0.84px;
		color: #000;
		&:hover {
			color: #000;
		}
	}
}

.settings-style {
	&.container-fluid {
		display: flex;
		flex-direction: column;
		max-width: 785px;
	}
	.style-item {
		> label {
			font-size: 12px;
			letter-spacing: 1.13px;
			color: #4a4a4a;
			margin: 50px 0 22px;
		}
		.collapse-btn {
			font-family: UntitledSans-Medium;
			font-size: 14px;
			letter-spacing: 0.82px;
			cursor: pointer;
		}
		&.fabrics {
			.color {
				img {
					width: 40px;
				}
			}
		}
		&.social {
			.options {
				flex-direction: column;
				max-height: none;
				span {
					display: flex;
					align-content: center;
					min-height: 77px;
					div:first-child {
						width: 247px;
					}
					label {
						font-family: UntitledSans-Regular;
						font-size: 14px;
						letter-spacing: 0.82px;
					}
					p {
						font-family: UntitledSans-Regular;
						font-size: 10px;
						letter-spacing: 0.58px;
						color: #979797;
					}
				}
				input {
					width: 228px;
					height: 30px;
				}
			}
		}
		&.what_matters_comments {
			.textarea-input-field {
				margin: 0;
				textarea {
					width: 400px;
					min-height: 163px;
					border-color: #979797;
				}
			}
		}
		&.body-issues-info{
			.section{
				display: flex;
				flex-direction: column;
				label{
					font-size: 12px;
					letter-spacing: 1.13px;
					color: #4a4a4a;
					margin: 10px 0 15px;
				}
				.input{
					&.container{
						max-width: 100%;
					}
				}
				textarea{
					min-height: 50px;
					min-width: 300px;
				}
			}
		}
		.options {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			max-width: 100%;
			max-height: 230px;
			overflow-y: scroll;

			&.collapsed {
				max-height: none;
			}

			.input {
				padding-right: 20px;
				width: auto;
				margin: 0;
				&.dropdown-input-field {
					.form-group {
						display: flex;
						flex-direction: row;
						align-items: center;
						label, select {
							font-family: UntitledSans-Regular;
							font-size: 14px;
							letter-spacing: 0.82px;
						}
						label {
							padding-right: 7px;
							margin: 0;
						}
						select {
							height: 30px;
							border: none;
							border-radius: 0;
							background-color: #fff;
							font-family: UntitledSans-Medium;
							color: #000;
							padding: 0 10px;
							min-width: 130px;
							text-align: left;
							&:after {
								position: absolute;
								right: 10px;
								top: 14px;
							}
						}
					}
				}
			}
			&.single {
				.input input[type=checkbox]:checked + label:after {
					width: 5px;
					height: 5px;
					top: 15px;
					left: 10px;
				}
			}
			&.multi {
				.input {
					.checkbox-label {
						&:before {
							border-radius: 0;
						}
					}
					input[type=checkbox]:checked + label {
						&:before {
							background: #000;
						}
						&:after {
							content: "";
							position: absolute;
							top: 12px;
							left: 10px;
							color: #fff;
							transform: rotate(45deg);
							height: 10px;
							width: 5px;
							border-bottom: 2px solid #fff;
							border-right: 2px solid #fff;
						}
					}
				}
			}
		}
	}
	.save-btn {
		order: 100;
		width: 220px;
		height: 54px;
		border-radius: 0;
	}
	.result-msg {
		order: 101;
		font-family: UntitledSans-Medium;
		font-size: 14px;
		&.error {
			color: #f00;
		}
	}
}

@media screen and (max-width: 576px) {
	.settings-main {
		h3 {
			font-size: 22px;
			padding-bottom: 6px;
		}
		.container-fluid {
			padding: 0;
		}
		.card {
			.card-title  {
				div {
					color :#000;
				}
				.arrow.right:after {
					width: 10px;
					height: 10px;
					border-width: 2px;
				}
			}
		}
		.deactivate {
			font-family: UntitledSans-Medium;
		}
	}

	.settings-info {
		&.container-fluid {
			max-width: 100%;
			padding: 0;
		}
		.header {
			padding-bottom: 15px;
			.profile-photo {
				display: flex;
			}
			.photo-btn p {
				display: flex;
				bottom: 0;
			}
		}
		form {
			max-width: 100%;
			.input {
				&.container {
					max-width: 100%;
					padding: 0;
					.form-row {
						width: 100%;
					}
				}
				.allow-dropdown {
					max-width: 100%;
					width: 576px;
					padding: 0;
				}
			}
			.form-control {
				width: 100%;
			}
			.gender {
				.options {
					display: flex;
					flex-direction: row;
					justify-content: space-evenly;
				}
				.input {
					width: auto;
					input[type=checkbox] + label {
						overflow: visible;
					}
					input[type=checkbox]:checked + label:after,
					input[type=checkbox] + label:before {
						min-width: 25px;
						min-height: 25px;
						border-radius: 15px;
						top: 4px;
						left: 5px;
					}
				}
			}
		}
	}

	.settings-style {
		.style-item {
			&.colors {
				.options {
					span {
						width: 33%;
					}
					.input {
						display: flex;
						align-items: center;
						padding: 0;
						.form-row, .color, .color img {
							width: 100%;
							object-fit: cover;
						}
					}
				}
			}
			&.budget_ranges, &.sizes {
				.options {
					flex-direction: column;
					.input {
						padding: 0;
						&.dropdown-input-field {
							.form-group {
								justify-content: space-between;
							}
						}
					}
					label {
						width: 150px;
					}
				}
			}
			&.social {
				.options {
					span {
						flex-direction: column;
						label {
							font-size: 16px;
						}
						p {
							margin-bottom: 0;
						}
						.input {
							padding: 0;
						}
					}
				}
			}
		}
	}

	.settings-password {
		&.container-fluid {
			padding: 0;
		}
		form {
			max-width: 100%;
			.input.container {
				max-width: 100%;
				padding: 0;
			}
			.form-control {
				width: 100%;
			}
		}
	}

	.settings-loyalty {
		.header {
			padding: 0;
			div {
				width: calc(100% - 100px);
			}
		}
	}

	.settings-payment-method {
		&.container-fluid {
			padding: 0;
		}
		form {
			.input-group, .StripeElement {
				max-width: 100%;
				border-radius: 0;
			}
		}
	}
}