.microplastic {
	@keyframes drop {
		0% {
			transform: translateY(0);
		}

		50% {
			opacity: 1;
		}

		100% {
			transform: translateY(100dvh);
			opacity: 0;
		}
	}

	position: relative;
	overflow: hidden;

	div {
		position: absolute;
		border-radius: 50%;
		animation: drop linear 0s 1 forwards;
	}
}