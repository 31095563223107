.cart {
	.retailer {
		font-family: UntitledSans-Medium;
		font-size: 14px;
		margin: 35px 0 16px 0;

		&:first-child {
			margin-top: 0;
		}
	}

	.cart-item {
		border: 1px solid #f4f4f4;
		padding: 10px 21px;
		position: relative;
		.row {
			align-items: center;
		}
		img {
			max-height: 200px;
			max-width: 150px;
			cursor: pointer;
		}
		.prices {
			display: block;
			cursor: pointer;
			p {
				display: inline-block;
			}
		}
		p {
			font-family: UntitledSans-Regular;
			font-size: 16px;
			line-height: 36px;
			letter-spacing: 0.71px;
			margin: 0;
			&.brand {
				text-transform: uppercase;
				letter-spacing: 1.58px;
			}
			&.price {
				font-family: UntitledSans-Medium;
				padding: 0 5px;
				&.line-through {
					font-family: UntitledSans-Regular;
					text-decoration: line-through;
				}
				&.discount {
					font-family: UntitledSans-Regular;
					color: #e02020;
				}
			}
			&.website {
				font-family: UntitledSans-Medium;
			}
			&.link {
				font-size: 14px;
				letter-spacing: 0.11px;
				line-height: 36px;
				margin-bottom: 12px;
				text-decoration: underline;
				cursor: pointer;
			}
			&.bold {
				font-weight: bold;
			}
			&.in-closet {
				font-family: UntitledSans-Regular;
				text-decoration: none;
				opacity: 0.6;
				cursor: default;
			}
		}
		a {
			color: #000;
			&:hover {
				color: #000;
			}
		}
		.buttons {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			padding-right: 83px;
			a, button {
				height: 50px;
				width: 158px;
				display: flex;
				justify-content: center;
				align-items: center;
				margin: auto 12px;
				text-transform: none;
				letter-spacing: 0.3px;
				font-size: 16px;
				border-radius: 4px;
			}
			a {
				color: #fff;
			}
		}
		.close {
			left: auto;
			right: 17px;
			top: 17px;
		}
		@media screen and (max-width: 768px) {
			border: none;
			border-bottom: 1px solid #f4f4f4;
			.col-12 {
				display: flex;
				flex-direction: column;
				align-items: center;
			}
			.image-col {
				img {
					max-height: 300px;
					max-width: 100%;
				}
			}
			a {
				text-align: center;
				height: 29px;
				width: 78px;
				display: flex;
				justify-content: center;
				align-items: center;
				text-transform: none;
				letter-spacing: 0.91px;
				font-size: 14px;
				border-radius: 4px;
				padding: 5px;
				color: #fff;
			}
			p {
				&.price, &.link, &.brand, &.name {
					font-size: 14px;
					cursor: pointer;
				}
			}
		}
	}
}

