.StripeElement {

	&.stripe-card-element {
		border: 1px solid #979797;
		padding: 14px 12px;
	}

	&.disabled {
		background-color: #dbdbdb;
		color: #5a5a61;
		pointer-events: none;
	}
}