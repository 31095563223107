.social-bar {
	font-size: 22px;

	a {
		color: #fff;
		padding: 10px 20px 0 0;

		&:hover {
			color: #fff;
		}
	}
}