.styling-session-checkout-page {
	display: flex;
	flex-direction: column;
	align-items: center;

	.header {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 18px;
		padding-top: 50px;
		max-width: 1500px;
		width: 100%;

		@media screen and (max-width: 768px) {
			padding-top: 16px;
		}

		.back-btn {
			left: 30px;
			top: 82px;

			@media screen and (max-width: 768px) {
				top: 38px;
			}
		}

		.stylist-image {
			border-radius: 50%;
			height: 78px;
			width: 78px;
			overflow: hidden;

			img {
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}

		.stylist-name {
			font-family: SabonLTPro-Italic, serif;
			font-size: 24px;
			letter-spacing: .24px;
		}
	}

	.subtitle {
		margin-top: 16px;
	}

	.session-type {
		display: flex;
		justify-content: center;
		gap: 14px;
		margin-top: 16px;

		@media screen and (max-width: 768px) {
			flex-direction: column;
			padding: 0 16px;
			width: 100%;
		}

		.session-type-selector {
			display: flex;
			gap: 10px;
			border: 1px solid #98A2B3;
			padding: 12px;
			border-radius: 4px;
			min-height: 70px;
			min-width: 300px;
			cursor: pointer;

			&.selected {
				background: #F9FAFB;

				.indicator:after {
					content: '';
					position: absolute;
					left: 2px;
					top:2px;
					width: 12px;
					height: 12px;
					background: #161616;
					border-radius: 50%;
				}

				.label {
					font-weight: bold;
				}
			}

			.indicator {
				flex-shrink: 0;
				position: relative;
				width: 20px;
				height: 20px;
				border-radius: 50%;
				border: 2px solid #161616;
				background: white;
			}

			.label {
				display: flex;
				flex-direction: column;
				gap: 4px;
				font-size: 16px;
				margin-top: 4px;
				width: 100%;

				b {
					display: flex;
					justify-content: space-between;
					font-weight: inherit;
				}

				i {
					display: block;
					color: #f88379;
					font-style: normal;
					font-size: 14px;
				}
			}
		}
	}

	.stripe-checkout-container {
		width: 100%;
		margin-top: 16px;
	}
}