.match {
	padding-top: 65px;
	height: 100vh;
	transition: all 2s ease 0s;

	.loader {
		position: fixed;
	}

	.matches-banner {
		background: #f4f4f4;
		padding: 35px 0;
		position: relative;

		.banner-content {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			max-width: 900px;
			margin: 0 auto;
			font-family: UntitledSans-Regular;

			p {
				font-size: 18px;
				letter-spacing: 1.43px;
				font-weight: 600;
			}
			.more-btn {
				max-width: 1080px;
				margin: 0 auto;
				div {
					width: 140px;
					font-size: 16px;
					letter-spacing: 0.48px;
					text-decoration: underline;
					cursor: pointer;
				}
			}
			.list-group {
				flex-direction: row;

				@media screen and (max-width: 576px) {
					flex-direction: column;
				}

				.banner-item {
					font-size: 16px;
					line-height: 30px;
					position: relative;
					padding: 0 20px;
					background: transparent;
					border: none;

					&:before {
						content: "";
						position: absolute;
						top: 8px;
						left: 2px;
						transform: rotate(45deg);
						height: 13px;
						width: 7px;
						border-bottom: 2px solid #f88379;
						border-right: 2px solid #f88379;
					}
				}
			}
		}
	}

	.header {
		margin: 30px 25px 35px;
		align-items: center;

		.title {
			font-family: SabonLTPro-Italic;
			font-size: 28px;
			line-height: normal;
			letter-spacing: 0.7px;
			color: #000;
			text-align: center;
			white-space: pre-line;

			&.results {
				font-family: UntitledSans-Medium;
				font-size: 20px;
			}
		}

		.meet {
			font-family: UntitledSans-Regular;
			font-size: 16px;
			margin-top: 15px;
			letter-spacing: normal;

			span {
				text-transform: capitalize;
			}
		}


		.col-6 {
			padding: 0;
		}

		.order-1 {
			align-items: center;
			display: flex;
		}

		.fa-heart {
			&.off {
				color: #000;
			}
		}

		a {
			display: flex;
			.fa-heart {
				color: #F46C67;
				font-size: 24px;
			}
		}

		.stylists-search-box {
			justify-content: flex-end;
			display: flex;
		}

		@media screen and (max-width: 576px) {
			margin: 10px auto;
			.title {
				margin-top: 22px;
				font-size: 30px;
				line-height: 1.32;
				letter-spacing: -0.9px;
			}
		}
	}


	.match-item-container {
		margin-bottom: 20px;
	}
	.more-stylists{
		position: relative;
		width: 100%;
		text-align: center;
		font-family: SabonLTPro-Italic;
		font-size: 28px;
		line-height: 42px;
		letter-spacing: 0.7px;
		color: #000;
		text-align: center;
		white-space: pre-line;
		margin-bottom: 30px;
		font-weight: 400;
		margin-top: 55px;
		margin-bottom: 35px;
	}

	@media screen and (max-width: 576px) {
		padding-top: 55px;


		.results {
			.col-12 {
				margin: 10px 0;
			}
		}

		.includes-banner {
			.banner-item {
				margin: 0 0 0 24px;
				line-height: 1.9;
				letter-spacing: 0.9px;
			}
		}
		.navbar {
			padding: 15px 10px;
			.logo {
				align-items: center;
				img {
					height: 25px;
					padding: 0;
				}
			}
		}
		.more-stylists{
			margin-bottom: 15px;
		}

	}
	
	
}