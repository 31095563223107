.lux {
	--maxContentWidth: 1040px;
	--sectionSidePadding: 40px;

	font-family: UntitledSans, sans-serif;
	line-height: 1;

	@media screen and (max-width: 576px) {
		--maxContentWidth: auto;
		--sectionSidePadding: 22px;
	}

	h2 {
		font-family: SabonLTPro-Italic, sans-serif;
		font-size: 30px;
		letter-spacing: -0.6px;
		text-align: center;
		font-weight: bold;
	}

	h3 {
		font-family: SabonLTPro-Italic, sans-serif;
		font-size: 30px;
		font-weight: bold;

		@media screen and (max-width: 576px) {
			font-weight: normal;
		}
	}

	p {
		line-height: 1.5;
		margin: 0;
		color: #7F7373;
		font-size: 18px;
	}

	ul, ol {
		list-style: none;
		margin: 0;
		padding: 0;
	}

	li {
		padding: 0;
	}

	.hero {
		display: flex;
		gap: 110px;
		height: 584px;
		background: #FFFBF5;
		justify-content: center;
		align-items: center;

		@media screen and (max-width: 576px) {
			flex-direction: column;
			gap: 42px;
			height: auto;
		}

		.left-side {
			width: 355px;

			@media screen and (max-width: 576px) {
				display: flex;
				flex-direction: column;
				align-items: center;
				width: auto;
			}

			img {
				display: block;
				height: 70px;

				@media screen and (max-width: 576px) {
					height: 55px;
					margin-top: 40px;
				}
			}

			p {
				font-size: 24px;
				letter-spacing: -0.68px;
				color: #4D4C4A;
				margin-top: 30px;

				@media screen and (max-width: 576px) {
					font-size: 22px;
					letter-spacing: -0.44px;
					line-height: 1;
					margin-top: 13px;
					max-width: 350px;
					text-align: center;
				}
			}

			button {
				width: 260px;
				margin-top: 50px;

				@media screen and (max-width: 576px) {
					display: none;
				}
			}
		}

		.right-side {
			img {
				width: 470px;
				height: 505px;
				object-fit: cover;

				@media screen and (max-width: 576px) {
					width: 390px;
					height: auto;
				}
			}
		}

	}

	.why {
		max-width: var(--maxContentWidth);
		margin: 0 auto;
		padding: 70px var(--sectionSidePadding) 50px var(--sectionSidePadding);

		@media screen and (max-width: 576px) {
			background: #FFFBF5;
			padding: 55px var(--sectionSidePadding);
		}

		ul {
			display: flex;
			justify-content: space-between;
			margin-top: 52px;

			@media screen and (max-width: 576px) {
				margin-top: 22px;
				flex-direction: column;
				align-items: center;
				gap: 30px
			}

			li {
				width: 280px;
				text-align: center;

				@media screen and (max-width: 576px) {
					width: 350px;
				}

				h3 {
					margin: 0 -30px;

					@media screen and (max-width: 576px) {
						font-size: 24px;
					}
				}

				p {
					margin-top: 20px;
				}
			}
		}
	}

	.use-cases {
		display: flex;
		flex-direction: column;
		align-items: center;
		background: #FFFBF5;
		padding: 70px var(--sectionSidePadding);

		@media screen and (max-width: 576px) {
			background: #FFFBF5;
			padding: 55px 0;
		}

		ul {
			display: flex;
			justify-content: center;
			gap: 80px;
			margin-top: 50px;
			padding: 0 var(--sectionSidePadding);

			@media screen and (max-width: 576px) {
				justify-content: normal;
				width: 100%;
				overflow: auto;
				scroll-snap-type: x mandatory;
				gap: 20px;
				margin-top: 22px;
			}

			li {
				width: 288px;
				text-align: center;

				img {
					width: 288px;
					height: 288px;
					object-fit: cover;
				}

				h3 {
					margin-top: 28px;
				}

				p {
					margin-top: 25px;
				}
			}
		}

		button {
			width: 260px;
			margin-top: 50px;

			@media screen and (max-width: 576px) {
				display: none;
			}
		}
	}

	.how-it-works {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 70px var(--sectionSidePadding);
		max-width: none;

		@media screen and (max-width: 576px) {
			padding: 55px var(--sectionSidePadding);
		}

		.flex-container {
			display: flex;
			gap: 36px;
			max-width: var(--maxContentWidth);
			margin-top: 50px;

			@media screen and (max-width: 576px) {
				margin-top: 30px;
			}

			img {
				width: 472px;
				/*height: 650px;*/
				object-fit: cover;

				@media screen and (max-width: 576px) {
					display: none;
				}
			}

			ol {
				display: flex;
				flex-direction: column;
				gap: 55px;

				li {
					h3 {
						font-size: 36px;

						span {
							color: #B6A799;
							font-weight: normal;
							font-style: italic;
						}
					}

					p {
						margin-top: 18px;
					}
				}
			}
		}
	}

	.schedule {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 70px var(--sectionSidePadding);
		background: #7B0B0B;
		color: white;

		p {
			color: white;
			margin-top: 37px;
			font-size: 22px;
			max-width: 870px;
			text-align: center;
		}

		button {
			display: flex;
			gap: 10px;
			align-items: center;
			justify-content: center;
			margin-top: 50px;
			width: 260px;
			border: none;

			 i {
				 display: inline-block;
				 width: 20px;
				 height: 20px;
				 background: url(https://media-cf.wishi.me/react/lux/phone_icon.png) center/cover no-repeat;
			 }
		}
	}

	.testimonials {
		display: flex;
		flex-direction: column;
		align-items: center;
		background: #FFFBF5;
		padding: 100px var(--sectionSidePadding) 50px;

		ul {
			display: flex;
			gap: 25px;
			margin-top: 50px;

			@media screen and (max-width: 576px) {
				justify-content: normal;
				width: 100%;
				overflow: auto;
				scroll-snap-type: x mandatory;
				gap: 20px;
				margin-top: 22px;
			}

			li {
				display: flex;
				flex-direction: column;
				align-items: center;
				width: 328px;
				background: white;
				border: 1px solid #ECE2DB;
				border-radius: 8px;
				padding: 40px 25px 25px 25px;
				text-align: center;

				@media screen and (max-width: 576px) {
					height: auto;
				}

				img {
					width: 70px;
					height: 70px;
					object-fit: cover;
					overflow: hidden;
					border-radius: 50%;
				}

				h3 {
					font-size: 20px;
					margin-top: 8px;
				}

				p {
					color: black;
					font-size: 16px;
					margin-top: 25px;
				}

				.author {
					font-family: SabonLTPro-Italic, sans-serif;
					font-size: 18px;
					margin: 15px 0;

					 &:before {
						 content: '—';
					 }
				}

				button {
					width: 260px;
					margin-top: auto;
				}

			}
		}
	}

	.questions {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 70px var(--sectionSidePadding);

		@media screen and (max-width: 576px) {
			padding: 55px var(--sectionSidePadding)
		}

		ul {
			margin-top: 50px;
			max-width: var(--maxContentWidth);

			@media screen and (max-width: 576px) {
				margin-top: 10px;
			}

			li {
				padding: 25px 0;
				border-bottom: 1px solid #EADED4;
				cursor: pointer;

				h3 {
					font-family: UntitledSans, sans-serif;
					font-weight: normal;
					font-size: 18px;

					&:before {
						display: inline-block;
						width: 30px;
						content: '+';
					}
				}

				p {
					margin: 5px 0 0 30px;
					font-size: 18px;
					color: #686868;
					line-height: 28px;

					// start hidden
					height: 0;
					overflow: hidden;
					transition: height 200ms ease-out;
				}

				&.open {
					h3:before {
						content: '-';
					}
					p {
						height: auto;
					}
				}

				&:last-child {
					border-bottom: none;
				}
			}
		}
	}

	.news {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 50px var(--sectionSidePadding) 100px;

		@media screen and (max-width: 576px) {
			padding: 55px var(--sectionSidePadding)
		}

		ul {
			display: flex;
			gap: 50px;
			margin-top: 50px;

			@media screen and (max-width: 576px) {
				flex-wrap: wrap;
				justify-content: center;
				gap: 28px;
				margin-top: 30px;
			}

			li {
				img {
					height: 40px;

					@media screen and (max-width: 576px) {
						height: 24px;
					}
				}
			}
		}
	}

	.fixed-button-container {
		position: fixed;
		display: none;
		justify-content: center;
		align-items: center;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 70px;
		background: white;
		border-top: 1px solid #bfbfbf;

		@media screen and (max-width: 576px) {
			display: flex;
		}

		button {
			width: 80%
		}
	}
}