.shopping-list {
	.loader {
		position: fixed;
	}
	.title {
		font-family: SabonLTPro-Italic;
		font-size: 28px;
		letter-spacing: 1.3px;
		text-align: center;
		padding: 25px 0 43px;
		margin: 0;
	}
	.empty {
		display: flex;
		flex-direction: column;
		align-items: center;
		img {
			max-width: 200px;
			padding-bottom: 36px;
		}
		a {
			width: 100%;
			text-align: center;
			button {
				margin-top: 28px;
				max-width: 274px;
				width: 88%;
			}
		}
		p {
			font-family: UntitledSans-Regular;
			font-size: 20px;
			color: #5a5a61;
			line-height: 55px;
			letter-spacing: 0.15px;
			margin: 0;
			&.nothing {
				font-size: 24px;
				color: #000;
			}
		}
	}
	@media screen and (max-width: 768px) {
		.title {
			font-size: 22px;
			padding-bottom: 30px;
		}
		.empty {
			p {
				font-size: 18px;
				text-align: center;
				line-height: 1.7;
				&.nothing {
					font-size: 18px;
					line-height: 55px;
				}
			}
		}
	}
}

.shopping-list-filter {
	font-family: UntitledSans-Regular;
	font-size: 14px;
	margin-bottom: 35px;
	&.container {
		max-width: 520px;
	}
	.row {
		.col {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			.filter-btn {
				cursor: pointer;
				&.active, &:hover {
					font-weight: 700;
					text-decoration: underline;
				}
			}
		}
	}

	@media screen and (max-width: 768px) {
		background-color: #f4f4f4;
		&.container {
			max-width: 100%;
			margin-bottom: 27px;
			.row {
				white-space: nowrap;
				.col {
					overflow-x: scroll;
					overflow-y: visible;
					.filter-btn {
						padding: 13px 10px;
						&.active {
							font-weight: 700;
							text-decoration: none;
							&:hover {
								font-weight: 700
							}
						}
						&:hover {
							font-weight: normal;
							text-decoration: none;
						}
					}
				}
			}
		}
	}
}