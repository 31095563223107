.footer {
	position: relative;
	background-color: #000;
	padding: 40px 55px 100px;
	width: 100%;

	.container {
		max-width: 700px;
	}

	.column-title {
		color: #ff584c;
		font-family: UntitledSans-Regular;
		text-transform: uppercase;
	}

	.nav-item {
		a {
			font-family: UntitledSans-Medium;
			color: #fff;
			font-weight: bold;
			line-height: 27px;
			font-size: 16px;
			letter-spacing: 0.7px;
			padding: 5px 0;
			display: block;

			&:hover {
				color: rgba(255, 255, 255, .7);
			}
		}

		img {
			max-width: 170px;
		}
	}

	.flex-column {
		margin-top: 20px;
	}
	@media screen and (max-width: 576px) {
		padding: 1px 55px 100px;
		}
}