.confirmation-page {
	.container {
		padding: 0;
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
		margin: 98px auto 0;
	}

	h1 {
		font-family: UntitledSans-Medium;
		font-size: 32px;
		letter-spacing: 1px;
		margin-bottom: 18px;
	}
	h4 {
		font-family: UntitledSans-Regular;
		font-size: 16px;
		line-height: 26px;
		letter-spacing: 0.22px;
		margin-bottom: 29px;
		white-space: pre-wrap;
	}
	.billing-details {
		font-family: UntitledSans-Regular;
		font-size: 16px;
		line-height: 26px;
		letter-spacing: 0.25px;
		.title {
			font-family: UntitledSans-Medium;
		}
		p {
			margin: 0;
		}
	}
	.closet {
		font-family: UntitledSans-Medium;
		font-size: 16px;
		letter-spacing: 0.45px;
		text-decoration: underline;
		padding-top: 26px;
		cursor: pointer;
		&.disabled {
			opacity: .5;
			pointer-events: none;
		}
	}
	.continue {
		width: 330px;
		margin: 32px 0 0;
		background-color: #f88379;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.confirmation-details-container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		min-height: 488px;
		height: 100%;
	}
	.item-image-container {
		display: flex;
		align-items: center;
		justify-content: center;
		max-width: 425px;
		height: 100%;
		border: 1px solid #dbdbdb;
		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
			padding: 40px;
		}
	}

	@media screen and (max-width: 768px) {
		max-width: 330px;
		margin: 29px auto;
		padding: 0 15px;
		h1 {
			font-size: 28px;
		}
		h4 {
			font-size: 14px;
		}
		.continue {
			width: 100%;
		}
	}
}