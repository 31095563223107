#matchItem{
	color:black;
}
.match-item {
	cursor: pointer;

	&.container-fluid {
		padding: 5px;
		border-radius: 4px;
		background-color: #f4f4f4;
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		height: 100%;
		position: relative;
		/*max-width: 300px;*/
		.favorite-button {
			right: auto;
			top: 12px;
			left: 12px;
			&.off {
				color: #fff;
			}
		}
	}

	.row {
		width: 100%;
		text-align: center;
		margin: 0;
	}

	.moodboard {
		img {
			width: 100%;
		}
	}
	.profileImage img {
		border: 3px solid #fff;
		margin-top: 10px;
		width: 64px;
		height: 64px;
		border-radius: 50%;
	}
	.name {
		font-family: SabonLTStd-Italic;
		font-size: 22px;
		margin-top: 20px;
	}
	.location {
		font-family: UntitledSans-Medium;
		font-size: 14px;
		text-transform: uppercase;
		letter-spacing: 1.1px;
	}
	.percentage {
		font-family: UntitledSans-Regular;
		font-size: 16px;
		letter-spacing: 1.1px;
		margin: 15px auto;
	}
	button {
		font-size: 16px;
		width: 88%;
		margin: 10px auto;
	}

	@media screen and (max-width: 576px){
		&.container-fluid {
			max-width: 100%;
		}
		.moodboard {
			.favorite-btn {
				width: 52px;
				height: 52px;
				padding: 10px;
			}
		}
		.name {
			margin-top: 10px;
		}
		.percentage {
			margin: 5px auto;
		}
	}
}
