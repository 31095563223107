.style-selector {
	.answers {
		width: 100%;
	}
	.carousel-inner{
		overflow: visible;

		.active {
			min-height: 500px;
			overflow-y: auto;
			overflow-x: hidden;
		}

		.carousel-item {
			.style {
				display: flex;
				flex-direction: column;
				align-items: center;

				.question {
					text-align: center;
				}
				img {
					max-width: 400px;
					width: 100%;
				}
			}
		}
	}
}