.birthday-message {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba(0, 0, 0, .8);
	z-index: 9999;

	.confetti {
		position: absolute;
		height: 100%;
		top: 0;
		width: 25%;
		&.right {
			right: 0;
		}
		&.left {
			left: 0;
		}
	}

	p {
		font-size: 50px;
		color: #fff;
		text-align: center;
		&.from {
			font-size: 28px;
			font-weight: 100;
		}
	}

	img {
		width: 160px;
		margin-top: 20px;
	}

	.col {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.close {
		width: 37px;
		height: 37px;
		background-color: #fff;
		border-radius: 20px;
		&:before, &:after {
			height: 13px;
			left: 18px;
			top: 12px;
		}
		&:not(:disabled):not(.disabled):hover {
			opacity: 1;
		}
	}
}