.orders {
	.order {
		.order-details {
			display: flex;
		}

		.order-info {
			font-size: 16px;
			letter-spacing: 0.35px;
			padding: 63px 109px 34px 0;
			label, p { margin: 0; }
			label { color: #5a5a61; }
			p { color: #000; }
		}
	}

	@media screen and (max-width: 576px) {
		.order {
			border-bottom: 1px solid #f4f4f4;
			max-width: 98%;
			margin: 0 auto;
			.order-info {
				padding: 30px 32px 28px 0;
			}
		}
	}
}

.order-item {
	display: flex;
	gap: 16px;
	width: 100%;
	border: 1px solid #f4f4f4;
	font-size: 16px;
	line-height: 36px;
	letter-spacing: 0.57px;

	.order-item-info {
		display: flex;
		flex-direction: column;
		justify-content: center;
		.brand, .price, .size { margin: 0; }
		.brand {
			text-transform: uppercase;
		}
		.size {
			text-transform: capitalize;
			display: flex;
			span {
				padding-right: 5px;
			}
		}
	}

	img {
		max-width: 150px;
		max-height: 222px;
		object-fit: contain;
		margin: 5px;
	}

	@media screen and (max-width: 576px) {
		border: none;
		line-height: 1.3;

		p {
			font-size: 14px;
		}

		img {
			max-width: 80px;
			max-height: 120px;
		}

	}
}