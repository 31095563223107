.favorites {
	justify-content: center;
	padding-top: 30px;

	.title {
		font-family: SabonLTStd-Italic;
		font-size: 28px;
		letter-spacing: 2.22px;
		text-align: center;
	}

	.empty {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 40px;
		p {
			font-family: UntitledSans-Regular;
			font-size: 24px;
			line-height: 55px;
			letter-spacing: 0.18px;
			text-align: center;
		}
		button {
			width: 274px;
		}
	}

	.stylist-item-container {
		margin-bottom: 20px;
	}

	.outfit {
		margin-bottom: 60px;
		.items {
			.container {
				padding: 0;
			}
			.shop-board {
				text-align: center;
				margin: 0;
				font-size: 14px;
				width: 100%;
			}
		}
	}

	@media screen and (max-width: 576px) {
		.outfit {
			.items {
				.container-fluid {
					background-color: #f4f4f4;
				}
			}
		}
	}
}