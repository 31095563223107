.wedding {
	.container-fluid {
		padding: 0 79px;
	}
	button {
		font-size: 16px;
		letter-spacing: 1.23px;
		border: none;
		width: 259px;
	}
	.carousel {
		.carousel-indicators {
			li {
				width: 20px;
				height: 20px;
				background-color: transparent;
				margin: 0 8px;
				opacity: 1;
				&.active {
					&:after {
						background-color: #000;
					}
				}
				&:after {
					content: '';
					display: block;
					width: 100%;
					height: 100%;
					background-color: #d8d8d8;
					border-radius: 10px;
				}
			}
		}
	}

	.header {
		display: flex;
		align-items: center;
		.carousel {
			max-height: 662px;
			overflow: hidden;
			.carousel-inner {
				.carousel-item {
					width: 100vw;
					position: relative;
					img {
						width: 100%;
					}
					.header-text {
						position: absolute;
						padding-left: 97px;
						z-index: 10;
						height: 100%;
						display: flex;
						flex-direction: column;
						justify-content: center;
						h1 {
							color: #fff;
							font-family: SabonLTPro-Italic;
							font-size: 60px;
							line-height: 65px;
							letter-spacing: -1.38px;
							white-space: pre-wrap;
							margin-bottom: 35px;
						}
						button {
							&:hover, &:active, &:focus {
								border: none;
							}
						}
					}
				}
			}
		}
	}
	.description {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		img {
			max-width: 79px;
			margin: 69px auto 30px;
		}
		p {
			font-family: UntitledSans-Regular;
			font-size: 18px;
			line-height: 36px;
			text-align: center;
			max-width: 828px;
			margin-bottom: 45px;
		}
		button {
			margin-bottom: 87px;
		}
	}
	.how {
		color: #0f0f0f;
		max-width: 500px;
		margin: 0 auto;
		h2 {
			font-family: SabonLTPro-Italic;
			font-size: 30px;
			letter-spacing: -0.69px;
		}
		label {
			font-family: UntitledSans-Medium;
			font-weight: 500;
			font-size: 18px;
			letter-spacing: -0.42px;
			margin: 28px 0 0;
		}
		p {
			font-family: UntitledSans-Regular;
			font-size: 16px;
			line-height: 28px;
			letter-spacing: -0.37px;
			margin: 0;
		}
	}
	.prices {
		max-width: 500px;
		margin: 0 auto;
		padding-top: 64px;
		color: #0f0f0f;
		.container-fluid {
			padding: 0;
			border-bottom: 1px solid #E1E1E1;
			.row {
				padding: 21px 0;
				justify-content: space-between;
				div {
					display: flex;
					flex-direction: column;
					justify-content: center;
					max-width: 366px;
				}
			}
		}
		h2 {
			font-family: SabonLTPro-Italic;
			font-size: 30px;
			letter-spacing: -0.69px;
			padding-bottom: 22px;
		}
		label {
			font-family: UntitledSans-Medium;
			font-weight: 500;
			font-size: 22px;
			letter-spacing: -0.51px;
			margin: 0 0 3px;
		}
		p {
			font-family: UntitledSans-Regular;
			font-size: 16px;
			line-height: 26px;
			letter-spacing: 0.87px;
			margin: 0;
		}
		.price {
			p {
				font-family: SabonLTPro-Italic;
				font-size: 30px;
				line-height: 35px;
				letter-spacing: 0.96px;
			}
		}
	}
	.stylists {
		padding-bottom: 107px;
		.carousel {
			max-width: 539px;
			margin: 0 auto;
			border: 1px solid #cdcdcd;
			.carousel-inner {
				height: 100%;
				padding: 48px 0 34px;
				.carousel-item {
					height: 100%;
					span {
						width: 100%;
						height: 100%;
						display: flex;
						flex-direction: column;
						align-items: center;
						justify-content: space-between;
						p {
							font-family: UntitledSans-Regular;
							font-size: 16px;
							line-height: 28px;
							letter-spacing: -0.37px;
							&.name {
								font-family: SabonLTPro-Italic;
								margin-bottom: 10px;
							}
						}
						.profile-image {
							width: 42px;
							height: 42px;
							object-fit: cover;
							border-radius: 21px;
							overflow: hidden;
							margin-bottom: 10px;
						}
						.moodboard {
							max-width: 414px;
							max-height: 416px;
							border: 1px solid #F7F7F7;
						}
						button {
							margin-top: 48px;
						}
					}
				}
			}
			.carousel-indicators {
				bottom: -68px;
			}
		}
	}
	.reviews {
		height: 589px;
		background-color: #F5F5F5;
		padding: 87px 0 57px;

		h2 {
			font-family: SabonLTPro-Italic;
			font-size: 30px;
			letter-spacing: -0.69px;
			padding-left: 89px;
			margin: 0;
		}

		.items {
			display: flex;
			justify-content: space-evenly;
			width: 100%;
			padding-top: 75px;
		}

		.review {
			color: #0f0f0f;
			display: flex;
			flex-direction: column;
			align-items: center;
			width: 414px;
			max-width: 33%;
			position: relative;
			img {
				width: 91px;
				height: 91px;
				border-radius: 45px;
				position: absolute;
				top: -45px;
			}

			span {
				display: flex;
				flex-direction: column;
				align-items: center;
				background-color: #fff;
				padding: 63px 22px 17px;
				height: 335px;
				width: 100%;

				label {
					font-family: SabonLTPro-Italic;
					font-size: 24px;
					letter-spacing: -0.55px;
					width: 100%;
					text-align: center;
					margin-bottom: 3px;
				}
				p {
					font-family: UntitledSans-Regular;
					font-size: 16px;
					line-height: 26px;
					letter-spacing: 0.03px;
					margin: 0 auto;
				}
				button {
					margin-top: 34px;
				}
			}
		}
	}
	.faq {
		height: 802px;
		.text {
			padding-top: 112px;
			max-height: 100%;
			overflow: scroll;
			h2 {
				font-family: SabonLTPro-Italic;
				font-size: 30px;
				letter-spacing: -0.69px;
				padding-bottom: 52px;
			}
			.question, h2 {
				max-width: 518px;
				margin: 0 auto;
			}

			.card, .card-header, .card-body, button {
				padding: 0;
				border: 0;
				border-radius: 0;
				background-color: transparent;
			}
			.card {
				border-top: 1px solid #AEAEAE;
				padding: 35px 0;

				&.opened {
					button {
						i {
							display: block;
							width: 50px;
							height: 30px;
							background: linear-gradient(#000,#000);
							background-position: center;
							background-size: 30px 2px;
							background-repeat: no-repeat;
						}
					}
				}

				button {
					font-family: SabonLTPro-Italic;
					font-size: 20px;
					line-height: 37px;
					letter-spacing: -0.46px;
					color: #000;
					text-align: left;
					width: 100%;
					box-shadow: none;
					display: inline-flex;
					justify-content: space-between;
					align-items: center;
					padding-right: 30px;
					&:hover, &:focus {
						text-decoration: none;
					}
					span {
						max-width: 379px;
					}
					i {
						display: block;
						width: 50px;
						height: 30px;
						background: linear-gradient(#000,#000), linear-gradient(#000,#000);
						background-position: center;
						background-size: 30px 2px, 2px 30px;
						background-repeat: no-repeat;
					}
				}

				.card-body {
					font-family: UntitledSans-Regular;
					font-size: 16px;
					line-height: 33px;
					letter-spacing: 0;
					padding-top: 10px;
					div a {
						color: #fa5649;
						&:hover {
							color: #fa5649;
							opacity: .7;
						}
					}
				}
			}
		}
		.images-column-1 {
			margin-right: 12px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			width: 338px;
			img {
				height: 50%;
				width: 100%;
				object-fit: cover;
				&:first-of-type {
					padding-bottom: 12px;
				}
			}
		}
		.images-column-2 {
			width: 408px;
		}
	}

	@media screen and (max-width: 768px) {
		.container-fluid {
			padding: 0 34px;
		}
		button {
			margin: 0 auto;
			width: 263px;
			font-size: 16px;
		}
		.header {
			.carousel {
				.carousel-inner {
					.carousel-item {
						img {
							height: 331px;
							object-fit: cover;
						}
						.header-text {
							width: 100%;
							padding: 0;
							margin: 0 auto;
							text-align: center;
							h1 {
								font-size: 30px;
								line-height: 32px;
							}
						}
					}
				}
			}
		}
		.description {
			img {
				max-width: 40px;
				margin: 32px auto 24px;
			}
			p {
				font-size: 16px;
				line-height: 27px;
				margin-bottom: 26px;
			}
			button {
				margin-bottom: 55px;
			}
		}
		.how {
			h2, label, p {
				width: 100%;
				text-align: center;
			}
			h2 {
				font-size: 24px;
			}
			label {
				margin: 40px 0 15px;
			}
		}
		.prices {
			h2 {
				width: 100%;
				font-size: 24px;
				text-align: center;
				padding-bottom: 0;
			}
			label, p {
				font-size: 16px;
			}
			label {
				line-height: 43px;
				margin-bottom: 8px;
			}
			p {
				line-height: 27px;
			}
			.price {
				position: absolute;
				width: 80px;
				right: 20px;
				padding-top: 10px;
				p {
					font-size: 24px;
				}
			}
		}
		.reviews {
			padding: 35px 0 26px;
			height: auto;

			h2 {
				padding: 0;
				width: 100%;
				text-align: center;
			}
			.items {
				padding: 0;
				flex-direction: column;
			}
			.review {
				padding-top: 45px;
				max-width: 100%;
				img {
					width: 45px;
					height: 45px;
					border-radius: 23px;
					position: relative;
					top: 0;
				}
				span {
					background-color: transparent;
					padding-top: 15px;
					height: auto;
					width: 100%;
					p {
						text-align: center;
						padding: 0 15px;
					}
					button {
						margin-top: 27px;
					}
				}
			}
		}
		.faq {
			height: auto;
			.text {
				padding: 40px 37px 0;
				h2 {
					font-size: 24px;
					width: 180px;
					text-align: center;
					padding-bottom: 20px;
				}
				.card {
					button {
						padding-right: 0;
						font-size: 18px;
						line-height: 20px;
					}
				}
			}
		}
	}
}