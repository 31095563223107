.StripeElement {

	&.payment-request-button {
		display: flex;
		flex-direction: column;
		border: 0;
		margin: 18px 0;
	}

	&.disabled {
		background-color: #dbdbdb;
		color: #5a5a61;
		pointer-events: none;
		opacity: .5;
	}
}