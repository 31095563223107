.onboarding .style {
	max-width: 100%;
	padding: 0;
	margin: 0;

	.question {
		margin-bottom: 25px;
	}

	.content {
		width: 100%;
		max-width: 560px;
		padding-bottom: 50px;
	}

	button {
		margin: 20px auto;
	}
}