.onboarding .gender {
	.container {
		padding: 0;

		.row {
			justify-content: center;
			align-items: center;
			gap: 18px;

			@media screen and (max-width: 576px) {
				flex-direction: column;
				gap: 14px;
			}
		}
	}

	.gender-button {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 260px;
		height: 302px;
		gap: 14px;
		border: 1px solid #979797;
		background: white;
		border-radius: 4px;
		cursor: pointer;

		@media screen and (max-width: 576px) {
			width: 200px;
			height: 210px;
			gap: 14px;
		}

		&.selected {
			border: 2px solid black;
		}

		.label {
			font-size: 16px;
			text-transform: uppercase;
			letter-spacing: -0.5%;
		}

		img {
			width: 236px;
			height: 236px;

			@media screen and (max-width: 576px) {
				width: 150px;
				height: 150px;
			}
		}
	}
}