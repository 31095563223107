.modal {
	&.EndSession, &.Error, &.DeactivateAccount {
		.modal-dialog {
			max-width: 300px;
		}
	}

	&.SaveStyleboard, &.SaveMoodboard, &.AddClosetItem {
		.modal-dialog {
			max-width: 800px;
		}
	}

	.modal-dialog {
		max-width: 600px;
	}

	.modal-content {
		padding: 54px;
		border: none;
		border-radius: 0;
	}

	.modal-header {
		border: none;
		flex-direction: column;
		align-items: center;
		text-align: center;
		padding: 0;

		.close {
			padding: 0;
			margin: 0;
			position: absolute;
			left: 20px;
			top: 15px;
			opacity: 1;
			span {
				display: none;
			}
		}

		.modal-title {
			font-size: 30px;
			letter-spacing: 1.5px;
			color: #000;
		}

		.modal-subtitle {
			text-align: center;
			font-size: 16px;
			color: #7F7F7F;
			margin-bottom: 28px;
		}
	}

	.modal-body {
		margin: 0 auto;
		padding: 0;

		input {
			padding: 10px;
			font-size: 16px;
			font-weight: 400;
			line-height: 20px;
			height: auto;
			border: 1px solid #d0d0d0;
			border-radius: 4px;
		}

		.form-row {
			align-items: center;
		}

		.form-check {
			padding: 0;
		}
	}

	.modal-footer {
		border: none;
		justify-content: center;
		padding: 0;

		.form-text {
			margin-top: 30px;
		}
	}

	.btn {
		padding: 11px 10px 7px 10px;
		font-size: 16px;
		font-weight: 500;
		width: 100%;
		letter-spacing: 1.9px;
		height: 47px;
		border: none;
		display: flex;
		justify-content: center;
		align-items: center;
		text-transform: uppercase;
		border-radius: 4px;
	}

	.form-label {
		font-size: 12px;
		text-align: left;
		color: #4a4a4a;
		text-transform: uppercase;
		margin-bottom: 3px;
		letter-spacing: 1.1px;
	}

	.form-text {
		font-size: 12px;
		letter-spacing: 0.9px;
		color: #4a4a4a;
	}

	@media screen and (max-width: 768px) {
		&.EndSession, &.Error {
			.modal-dialog {
				max-width: 100%;
			}
		}
		.btn {
			height: auto;
		}
	}

	@media screen and (max-width: 576px) {
		padding-right: 0 !important;

		.modal-dialog {
			height: 100dvh;
			margin: 0;
		}

		.modal-content {
			height: 100%;
			padding: 20px;
		}
	}
}