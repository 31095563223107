.how-it-works{
    overflow: hidden;
	padding: 0;
	max-width: 1440px;
	a:hover {
		color: #fff;
		text-decoration: none;
		
	}
	&.warpper, &.fluid{
		max-width: none;
	}
	&.no-overflow{
		overflow: visible;
	}
	&.btn{ 
		&.btn-dark{
			height: 50px;
			padding-top: 17px;
			overflow: visible;
			font-size: 15px;
		}
	}
	.pageHeader{
		position: relative;
		max-width: 330px;
		width: 100%;
		height: 51px;
		left: 38%;
		margin-top: 66px;	
		font-family: SabonLTStd-Italic;
		font-weight: 400;
		font-style: italic;
		font-size: 40px;
		line-height: 51px;
		letter-spacing: -0.5%; 
		text-align: center;
		margin-bottom: 89px;
	}
	@media screen and (max-width: 550px) {
		.pageHeader{
			width: 171px;
			height: 40px;
			top: 0px;
			margin-bottom: 10px;
			font-weight: 400;
			font-size: 28px;
			line-height: 40px;
			margin-top: 30px;	
			text-align: center;
			letter-spacing: -0.005em;
			color: #000000;
			left: calc(25% + (100vw - 375px )/2) ;
		}
	}
	.section{
		position: relative;
		display: flex;
		margin-top: 65px;
		flex-direction: row;
		margin-left: 142px;
		&.s-02{
			margin-top: 57px;
			margin-left: 120px;
		}
		&.s-06{
			margin-top: 72px;
		}
		&.header, &.styling{
			margin-top: auto;
			margin-left: auto;
			@media screen and (max-width: 550px) {
				align-items: baseline;
			}
		}
		@media screen and (max-width: 550px) {
			flex-direction: column;
			margin-left: 0px;
			&.s-02{
				margin-top: 0px;
				margin-left:0px;
			}
			&.s-06{
				margin-top: 0px;
			}
		}
	
		.sectionHeader{
			display: flex;
			flex: 50%;
			width:100%;
			@media screen and (max-width: 550px) {
				width: 535px;
				order: 1;
				margin-left:0px;
			}
			&.right{
				order:   2;
				@media screen and (max-width: 550px) {
					margin-right:0;
					justify-content:center;
				}
			}
			&.left{
				order:1;
				padding-left: 20px;
				justify-content: left;
				@media screen and (max-width: 550px) {
					margin-left:0;
					padding-left: 0px;
					justify-content:center;
				}
			}
			&.text01{
				margin-top: 42px;
				&.secondaryTitle{
					margin-top: 3.7px;
					width:100%;
					max-width: 425px;
				}
			}
			&.text02{
				margin-top: 42px;

				&.header{
					width:494px;
					padding-left:90px;
				}
				&.secondaryTitle{
					margin-top: 14px;
					width:100%;
					max-width: 380px;
				}
			}
			&.text03{
				margin-top: 28px;
				&.header{
					max-width: 470px;
					width:100%;
				}
				&.secondaryTitle{
					margin-top: 15.5px;
					width:100%;
					max-width: 410px;
				}
			}
			&.text04{
				margin-top: 47px;
				&.secondaryTitle{
					margin-top: 13px;
					max-width: 400px;
					width:100%;
					flex-wrap: wrap;
					margin-bottom: 8px;
				}
			}
			&.text05{
				margin-top: 43px;
				&.header{
					width:470px;
					width:100%;
					max-width: 480px;
				}
				&.secondaryTitle{
					margin-top: 14px;
					width:407px;
				}
			}
			&.text06{
				margin-top: 40px;
				&.secondaryTitle{
					margin-top: 15px;
					width:100%;
					max-width: 380px;
				}
			}
			@media screen and (max-width: 550px) {
				&.text01{
					margin-top: 0;
					&.secondaryTitle{
						margin-top: 2px;
						width: 290px;
					}
				}
				&.text02{
					margin-top: 0;
					&.header{
						width: 221px;
						padding-left:0px;
					}
					&.secondaryTitle{
						margin-top: 10px;
						width: 290px;
					}
					
				}
				&.text03{
					margin-top: 0;
					&.header{
						width: 241px;
					}&.secondaryTitle{
						margin-top: 10px;
						width: 290px;
					}
				}
				&.text04{
					margin-top: 0;
					right: 0;
					&.header{
						padding-left: 0px;
					}
				    &.secondaryTitle{
						margin-top: 20px;
						width: 290px;
					}
				}
				&.text05{
					margin-top: 0;
					&.header{
						width: 221px;
					}
					&.secondaryTitle{
						margin-top: 10px;
						width: 290px;
					}
				}
				&.text06{
					margin-top: 0;
					&.header{
						width: 221px;
						padding-left: 0px;
					}
					&.secondaryTitle{
						margin-top: 10px;
						width: 290px;
					}
				}
			}
			
		}
	
		.sectionNumber{
			position: relative;
			width: 36px;
			height:44px;
			font-size: 31px;
			font-weight: 700;
			line-height: 43px;
			display: block;
			font-family: SabonLTStd-Italic;
			border-bottom: 0.15rem solid #c9c4c4;
			margin-bottom: 27px;
			@media screen and (max-width: 550px) {
				left:47%;
				margin-bottom: 10px;
				font-weight: 700;
				font-size: 16px;
				line-height: 10px;
				width: 18px;
				height:14px;
				margin-top: 25px;
				border-bottom: 0.12rem solid #c9c4c4;
			}

		}
		
		.underLine{
			width: 38px;
			height: 0;
			position: relative;
			display: block;
			background-color: #c9c4c4;
			border: 1px solid #c9c4c4;
			
		}
		.header{
			position: relative;
			display: block;
			overflow: hidden;
			font-weight: 400;
			max-width: 550px;
			height: fit-content;
			line-height: 53px;
			font-size: 38px;
			white-space: pre-line;
			letter-spacing: -0.005em;
			font-family: SabonLTStd-Italic;
			&.right{
				padding-left: 78px;
			}
			&.longText{
				max-width: 470px;
			}
			@media screen and (max-width: 550px) {
				&.header{
					font-size: 24px;
					line-height: 35px;
					margin-bottom: 15px;
					font-size: 24px;
					text-align: center;
				}
			}

		}
		
		.secondaryTitle{
			position: relative;
			left: 0px;
			white-space: pre-line;
			margin-top: 18px;
			color: #5A5A61;
			font-family: UntitledSans-Regular;
			font-size: 20px;
			line-height: 32px;
			font-weight: 400;
			margin-bottom: 0;
			word-spacing: 1px;
			@media screen and (max-width: 550px) {
				order: 3;
				font-size: 15px;
				line-height: 23px;
				font-weight: 400;
				font-family: UntitledSans-Regular;
				margin-top: 0px;
			}
		}
		
		
	}
	@media screen and (max-width: 550px) {
		.section{
			margin-top: 0;
			flex-direction: column;
			align-items: center;
		}
	}
	.letsGet{
		position: relative;
		text-align: center;
		width: fit-content;
		height: fit-content;
		overflow: hidden;
		left:539px;
		margin-top: 33px;
		margin-bottom: 105px;
		@media screen and (max-width: 550px) {
			margin-top: 25px;
			margin-bottom: 50px;
			left:calc(20px + (100vw - 375px)/2);
			align-items: center;
		}
		.styleTitle{
			font-family: SabonLTStd-Italic;
			font-weight: 400;
			font-size: 32px;
			line-height: 40px;
			text-align: center;
			letter-spacing: -0.005em;
			@media screen and (max-width: 550px) {
				width:250px;
				font-size: 22px;
				line-height: 30px;
				margin-left: 45px;
			}

		}
		.helpText{
			font-weight: 400;
			font-size: 16px;
			line-height: 24px;
			@media screen and (max-width: 550px) {
				font-size: 15px;	
			}
		}
	}
	.reviews-section{
		background-color: #F4F4F4;
		height: 648px;
		@media screen and (max-width: 550px) {
			height: 540px;
		}
		.reviews-header{
			position: relative;
			top:69px;
			left:522px;
			font-size: 36px;
			font-family: SabonLTStd-Italic;
			font-weight: 400;
			line-height: 51px;
			text-align: center;
			letter-spacing: -0.005em;
			@media screen and (max-width: 550px) {
				left:20.5%;
				display: flex;
				justify-content: center;
				font-size: 28px;
				line-height: 38px;
				top:35px;
				width: 220px;
				white-space: pre-line;
				text-align: center;
				
			}
		}
		
	}
	a{
		&.link{
			position: relative;
			font-family: UntitledSans-Regular;
			/*text-decoration: underline;*/
			border-bottom: 0.12rem solid #212529;
			color: #212529;
			font-size:20px;
			font-weight: 600;
			padding-left: 1.3px;
			cursor: pointer;
			line-height: 63px;
			word-spacing: 4.5px;
			@media screen and (max-width: 550px) {
				font-size:15px;
				font-weight:600;
				margin-top: -10px;
				line-height: 48px;
			}
		}
	}
}
.stepsContainer{
	display: flex;
	order: 1;
	flex:50%;
	&.left{
		&.image01{
		padding-left: 70px;
		}
		&.image03{
		padding-left: 0px;
		margin-right: 120px;
		}
		&.image05{
			padding-left: 68px;
		}
	}
	@media screen and (max-width: 550px) {
		order: 2;
		&.left{
			&.image01{
			padding-left:0px;
			}
			&.image03{
			padding-left: 0px;
			margin-right: 0px;
			}
			&.image05{
				padding-left: 0px;
			}
		}
		
	}
}
.carousel{
	/*max-width: 1440px;*/
	&.container-fluid{
		padding:0;

		@media screen and (max-width: 550px) {
			padding-left: 0px;
			padding-right: 0px;
		}
	}
}

.reviews-carousel{
	top:127px;
	overflow: hidden;
	max-height:372px;
	margin-bottom: 50px;
	-webkit-tap-highlight-color: transparent;
	@media screen and (max-width: 550px) {
		max-height: 402px;
		top:60px;
		overflow:visible;
	}
	.slick-current {
		@media screen and (max-width: 550px) {
			margin: 0 calc(-15px + (375px - 100vw));
		}
		
	
	}
	
	.slick-slide{
		@media screen and (min-width: 950px) {
			width:390px!important;
			margin: 0 0px;
		}
	}

	.slick-list {
		padding:0 190px !important;
		margin: 0 -77.5px;
		
		@media screen and (max-width: 550px)  {
			padding:0 calc(30px + ((100vw - 375px)/0.7)) !important;
			margin: 0 4px;
		}
	}
	
	.slick-prev{
		  transition: opacity 800ms;
		left:50px;
		@media screen and (max-width: 550px){
			left:20px;

		}
		
	}
	.slick-next{
		  transition: opacity 800ms;
		right: 50px;
		@media screen and (max-width: 550px){
			left:20px;

		}
		
	}
	.slick-prev:before{
		content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='23' viewBox='0 0 100 100'  fill='white' %3E%3Cpath d='M 10,50 L 60,100 L 70,90 L 30,50 L 70,10 L 60,0 Z'/%3E%3C/svg%3E%0A");
		color: white;
	
	}
	.slick-next:before{
		content:  url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='23' viewBox='0 0 100 100'  fill='white' %3E%3Cpath d='M 10,50 L 60,100 L 70,90 L 30,50 L 70,10 L 60,0 Z' transform='translate(100,100) rotate(180)'/%3E%3C/svg%3E%0A");
		color: white;
		
	}
	.slick-prev:hover, .slick-prev:focus, .slick-next:hover, .slick-next:focus{
		background-color: #0000005e;
	}
	button.slick-arrow {
		opacity: 0;
		z-index: 1;
		width: 67px;
		height: 67px;
		border-radius: 100%;
		background-color: #0000005e;
	}
	button.slick-arrow:hover{
	
		background-color: #a1a0a070;
	}
}
.reviews-carousel:hover button.slick-arrow {
	opacity: 1.0;
	
}


p{
	&.stepText{
		margin-bottom: 22px;
		@media screen and (max-width: 550px) {
			margin-bottom: 12px;
		}
	}
	@media screen and (max-width: 550px) {
		&.stepText{
			font-weight: 400;
			font-size: 15px;
			line-height: 23px;
			text-align: center;
			
		}
	}
}
a.styling-button.how-it-works{
	width: 260px;
	height: 45px;
	font-weight: 500;
	line-height: 24px;
	overflow: visible;
	margin:28px auto;
	padding: 15px 40px;


	@media screen and (max-width: 550px) {
		margin: 20px 30px;
		width: 280px;
	} 
}  
a.helpCenter{
	text-decoration: none;
	color:black;
	font-size: 18px;
	font-weight: 500;
	line-height: 27px;
	@media screen and (max-width: 550px) {
		font-size: 15px;
		
	}
}  

a.helpCenter:hover{
	color:black
}
img.sectionImage{
	position: relative;
	width:100%;
	
	&.withFrame{
		left:39px;
		margin-top: 0px;
		margin-bottom: 31px;
		box-shadow: 0px 1.99463px 9.97314px rgba(0, 0, 0, 0.2);
		border-radius: 23.9355px;
		max-width: 371px;
		height: 666px; 
	
	}
	&.image01{
		margin-top: 0px;
		margin-bottom: 0px;
		height: 540px;
		max-width:410px;
	}
	&.image02{
		left:-17px;
		margin-bottom: 65px;
		max-width: 560px;
		height:648px;
		
	}
	&.image03{
		padding-right:0px;
		padding-left: 0px !important;
		margin-bottom: 0px;
		height:493px;
		max-width: 590px;
	} 
	&.image05{
		right:60px;
		margin-bottom:0px;
		max-width: 518px;
		height: 590px;
	}
	&.image06{
		left:31px; 
		margin-bottom: 65px;
		max-width:386px;
		height: 693px;
	}
	@media screen and (max-width: 550px) {
		&.image01{
			height:347px;
			width: 285px;
			border-radius: 10.9179px;
			right: 0;
			margin-right:0;
			margin-bottom: 10px;
		}
		&.image02{
			left:22px;
			height: 387.37px;
			width: 338.23px;
			margin-right: 46px;
			margin-bottom: 0px;
		}
		&.image03{
			width: 304.47px; 
			height:283.23px;
			margin-bottom: 0px;
			left:0;
		}
		&.withFrame{
			left:0px;
			margin-top: 2px;
			margin-bottom: 0px;
			box-shadow: 0px 1.99463px 9.97314px rgba(0, 0, 0, 0.2);
			border-radius: 23.9355px;
			width: 275px;
			height: 492.76px; 
			border-radius: 15.1665px;
		}
		&.image05{
			margin-bottom: 0;    
			width:304.27px;
			height:341.43px; 
			left:0;
		}
		&.image06{
			left:0; 
			margin-bottom:0;
			width: 275px;
			height: 447.05px;
			border-radius: 16.7964px;
		}
	}
}