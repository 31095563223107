.add-review {
	width: 100%;
	max-width: 490px;
	margin: 0 auto;

	.loader {
		width: 100%;
		height: 100%;
		background-color: rgba(255, 255, 255, .7);
		position: absolute;
		top: 0;
		left: 0;
		z-index: 999;
		pointer-events: none;
	}

	.modal-header {
		.profile-picture {
			border-radius: 50%;
			width: 95px;
			height: 95px;
			margin: 15px;
		}
	}
	.modal-body {
		font-family: UntitledSans-Regular;
		max-width: 100%;
		text-align: center;
		font-size: 18px;
		letter-spacing: 0.59px;

		.rating {
			width: 100%;
			margin: 18px auto;
		}

		textarea {
			font-family: UntitledSans-Regular;
			border: 1px solid #dbdbdb;
			border-radius: 2px;
			width: 100%;
			height: 204px;
			font-size: 18px;
			line-height: 25px;
			letter-spacing: 0.59px;
			&::placeholder {
				color: #dbdbdb;
			}
		}

		.upload-section {
			height: 115px;
			margin: 17px 0;

			.upload-btn {
				padding: 0;
				input {
					color: transparent;
					width: 0;
					height: 0;
					opacity: 0;
					position: absolute;
					left: 0;
					top: 0;
				}
				label {
					font-family: UntitledSans-Medium;
					font-size: 16px;
					letter-spacing: 0.52px;
					width: 100%;
					height: 43px;
					border: 1px solid #dbdbdb;
					display: flex;
					justify-content: center;
					align-items: center;
					cursor: pointer;

					@media screen and (max-width: 576px) {
						font-size: 13px;
					}

					svg {
						width: 20px;
						height: 20px;
						margin-right: 8px;
					}
				}
			}

			.upload-image {
				max-width: 100px;
				max-height: 107px;
				padding: 0;
				overflow: hidden;

				.remove {
					width: 17px;
					height: 17px;
					background-color: #fff;
					border-radius: 50%;
					position: absolute;
					top: 3px;
					left: 3px;

					.close {
						width: 10px;
						height: 10px;
						left: -1px;
						top: 4px;
						&:before, &:after {
							left: 9px;
						}
					}
				}
				img {
					width: 100%;
				}
			}
		}
	}

	.modal-footer {
		button {
			height: 60px;
			font-size: 16px;
		}
		p {
			font-family: UntitledSans-Regular;
			font-size: 16px;
			letter-spacing: 0.52px;
		}
	}
}