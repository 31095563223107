.onboarding {
	font-family: Roboto, sans-serif;
	background: #f4f4f4;
	min-height: 100%;
	padding-top: 70px !important;

	.content {
		margin: 0 auto;

		@media screen and (max-width: 576px) {
			max-width: 310px;
			padding-top: 35px;
		}
	}
	.question {
		font-family: Sabon, sans-serif;
		font-style: italic;
		font-size: 28px;
		line-height: normal;
		letter-spacing: 0.7px;
		color: #000;
		text-align: center;
		margin-bottom: 55px;
		flex-direction: column;
		white-space: pre-line;

		@media screen and (max-width: 576px) {
			margin-bottom: 40px;
			font-size: 22px;
			line-height: 1.32;
			letter-spacing: -0.9px;
		}
	}
	button {
		margin: 60px auto;
		align-self: center;
		display: flex;
		width: 400px;
		max-width: 100%;
		font-weight: bold;
		flex-direction: column;
		align-items: center;
	}
	.sticky-button {
		background-color: #f4f4f4;
		padding: 12px 22px;
		left: 0;

		button {
			margin: 10px 0;
			width: 100%;
		}
	}
}