.chat-container {
	height: 100%;
	display: flex;
	flex-direction: column;

	.wishi-chat {
		height: 100%;
	}

	.chat-wrapper {
		height: 100%;
		max-height: 100%;
	}

	.input-wrapper {
		flex: none;
		margin-top: auto;
		height: auto;
	}
}