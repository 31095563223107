.onboarding-header.nav-bar {
	@media screen and (max-width: 576px) {
		padding: 15px 10px;
		.logo {
			margin: 0 7px 0 0;
			align-items: center;
			img {
				padding: 0;
			}
		}
	}

	.mobile-step-counter {
		font-size: 12px;
		letter-spacing: 0.8px;
		text-transform: uppercase;
		color: #000;
		white-space: pre;
	}

	.mobile-back {
		margin: 2px 4px;
		border: solid #000;
		border-width: 0 2px 2px 0;
		padding: 4px;
		transform: rotate(135deg);
	}
}