.steps-progress-bar {
	margin-bottom: 35px;
	position: relative;
	max-width: 620px;

	@media screen and (max-width: 768px) {
		max-width: 500px;
	}

	@media screen and (max-width: 576px) {
		max-width: none;
		padding: 0;
		margin: 0;
	}

	.row {
		@media screen and (max-width: 576px) {
			margin: 0 auto;
		}

		.col {
			padding: 0;
		}
	}

	.back-btn {
		left: -100px;

		@media screen and (max-width: 768px) {
			display: none;
		}
	}

	.progress {
		height: 2px;
		width: calc(100% - 60px);
		margin: 45px auto;
		background-color: #4a4a4a;

		@media screen and (max-width: 576px) {
			height: 7px;
			border-radius: 0;
			width: 100%;
			margin: 0;
		}

		.progress-bar {
			background-color: #F88379;
		}
	}

	.steps {
		position: absolute;
		display: flex;
		justify-content: space-between;
		width: calc(100% - 30px);
		top: 36px;
		left: -15px	;
		z-index: 2;

		@media screen and (max-width: 576px) {
			display: none;
		}

		.step {
			position: relative;
			width: 18px;
			height: 0;

			.step-content {
				position: absolute;
				display: flex;
				align-items: center;
				flex-direction: column;


				&.done {
					cursor: pointer;

					.circle {
						&:after {
							content: '';
							position: relative;
							top: 3px;
							left: 6px;
							display: block;
							width: 6px;
							height: 12px;
							border: solid #000;
							border-width: 0 2px 2px 0;
							transform: rotate(45deg);
						}
						&:hover {
							box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.3);
							transition: box-shadow 250ms;
						}
					}
				}

				.circle {
					background: #fff;
					width: 18px;
					height: 18px;
					border-radius: 50%;
				}

				.label {
					font-family: UntitledSans, sans-serif;
					color: #000;
					letter-spacing: 0.4px;
					font-size: 14px;
					font-weight: 500;
					text-transform: uppercase;
					white-space: nowrap;
					margin-top: 15px;
				}
			}
		}


	}


}