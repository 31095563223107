.our-story {
	margin-bottom: 180px;

	.header {
		background-image: var(--our-story-header-bg);
		background-size: 550px;
		background-repeat: repeat;
		max-height: 550px;
		overflow: hidden;
		display: flex;
		align-items: center;

		.col {
			display: flex;
			align-items: center;
			justify-content: center;
			&.karla {
				background: radial-gradient(ellipse at 30% 50%, rgba(255, 255, 255, 0.2) 0%, rgba(47, 47, 47, 0) 70%);
				max-width: 550px;
				img {
					width: auto;
					max-height: 550px;
				}
			}
			&.text {
				position: absolute;
				right: 0;
				width: 90%;
				p {
					font-family: SabonLTPro-Italic;
					font-size: 32px;
					letter-spacing: 2.23px;
					line-height: 62px;
					text-align: center;
					color: #fff;
					max-width: 630px;
					white-space: pre-line;
				}
			}
		}
	}
	.body {
		font-family: UntitledSans-Regular;
		margin-top: 60px;
		font-size: 18px;
		line-height: 35px;
		letter-spacing: .6px;
		text-align: center;
		color: #000;
		white-space: pre-line;
		padding: 0;
		.col {
			padding: 0;
		}
		p {
			width: 90%;
			margin: 0 auto;
		}
		video {
			margin: 40px 0 45px;
			max-width: 100%;
			max-height: 658px;
		}
		.welcome {
			font-family: SabonLTPro-Italic;
			font-size: 24px;
			line-height: 45px;
			letter-spacing: 1.3px;
			margin: 10px 0 16px;
			width: 100%;
		}
		.signature {
			width: 170px;
		}
	}

	@media screen and (max-width: 576px) {
		.header {
			position: relative;
			max-height: 340px;
			.col{
				&.karla img {
					max-height: 340px;
				}
				&.text {
					width: 100%;
					p {
						line-height: 35px;
						font-size: 18px;
					}
				}
			}
		}
		.body {
			font-size: 16px;
			line-height: 24px;
			margin: 50px 0 0;
			padding: 0 15px;
			p {
				width: 100%;
				padding: 0 45px;
			}
			.welcome {
				font-size: 14px;
				line-height: 24px;
			}
			.signature {
				width: 128px;
			}
		}
	}
}