.express-checkout-container {
	position: relative;

	&.disabled {
		opacity: 0.5;
		pointer-events: none;
	}

	.login-opener {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		cursor: pointer;
	}

	.error-message {
		margin-top: 10px;
		color: red;
	}

	.StripeElement.payment-request-button {
		margin: 0;
	}
}