.signup-modal {
	width: 100%;
	max-width: 390px;
	margin: 0 auto;

	@media screen and (max-width: 576px) {
		max-width: 336px;
	}


	.logo {
		height: 38px;
		margin-bottom: 22px;
		object-fit: cover;
		background: url(https://media-cf.wishi.me/react/landing/wishi_black.svg) center / contain no-repeat;
		cursor: pointer;

		@media screen and (max-width: 576px) {
			display: none;
		}
	}

	.modal-header {
		margin-bottom: 24px;

		.title {
			font-family: Sabon, sans-serif;
			letter-spacing: 1.5px;
			font-style: italic;
			margin-bottom: 10px;
			font-size: 24px;
			line-height: 1;

			@media screen and (max-width: 576px) {
				margin-top: 30px
			}
		}

		.subtitle {
			text-align: center;
			font-size: 16px;
			line-height: 1;
			color: #7F7F7F;
		}
	}

	form {
		.input-container {
			margin-bottom: 16px;

			input {
				width: 100%;
				font-size: 16px;
				padding: 15px;
				border: 1px solid #DADFE4;
				border-radius: 4px;

				&:focus {
					outline: none;
				}

				&[type='checkbox'] {
					all: unset;
					appearance: none;
					background: none;
					border: none;
					position: relative;
					width: 16px;
					height: 16px;
					margin: 0;
					cursor: pointer;

					&:before {
						content: '';
						position: absolute;
						left: 0;
						top: 0;
						width: 16px;
						height: 16px;
						background: white;
						border: 1px solid black;
						border-radius: 3px;
					}

					&:checked {
						&:before {
							background-color: black;
						}

						&:after {
							content: '';
							position: absolute;
							left: 3px;
							top: 3px;
							width: 10px;
							height: 10px;
							background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='4 4 7.5 7.5'%3E%3Cpath fill='white' fill-rule='evenodd' d='M6.5 10.5L4 8l1-1 1.5 1.5 4-4 1 1z'/%3E%3C/svg%3E");
						}
					}
				}

			}

			label {
				font-size: 14px;
				display: block;
				margin: 0;
			}

			.validation-error {
				margin-top: 5px;
				color: #dc3545;
				font-size: 13px;

				&:empty {
					display: none;
				}
			}
		}

		.actions {
			display: flex;
			justify-content: space-between;
			align-items: center;

			.form-check {
				padding: 0;
			}
		}

		.submit-button {
			display: block;
			width: 100%;
			font-size: 16px;
			font-weight: bold;
			padding: 16px;
			letter-spacing: 1.2px;
			border: none;
			background: black;
			border-radius: 4px;
			color: white;
			text-transform: uppercase;
			cursor: pointer;

			&[disabled] {
				opacity: 0.5;
				pointer-events: none;
			}
		}
	}

	strong {
		cursor: pointer;
	}

	.legal, .actions {
		margin-bottom: 26px;
		font-size: 14px;
		letter-spacing: 0.3px;
		text-align: center;

		a {
			font-weight: bold;
			color: black;
		}
	}

	.existing-user-message, .no-account-message {
		font-size: 16px;
		margin-top: 14px;
		text-align: center;
	}

	.remember-me {
		display: flex;
		gap: 5px;
	}
}